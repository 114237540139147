import React from 'react';
import { IDepartureItem } from '../../../reducers/anytrip-api.types';
import { renderDepartureTime, departureItemToModeIcon } from "../common/utils";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { abbreviateStationName } from '../../../utils';

interface SpiPrecinctDeparturesProps {
    precinctDepartures: IDepartureItem[];
    capacity?: boolean;
}
export const SpiPrecinctDepartures: React.FC<SpiPrecinctDeparturesProps> = ({precinctDepartures, capacity}) => {
    return <>
        <div className="spi-precinct-departures-header">
          <div className="header">
            <div className="header-item service">Service</div>
            <div className="header-item platform">Plat</div>
            <div className="header-item departs">Departs</div>
            {capacity && <div className="header-item capacity">Capacity</div>}
          </div>
        </div>
        {precinctDepartures.map((departure, i) => {
          const headsign =
            departure.stopTimeInstance.stopHeadsign ||
            departure.tripInstance.trip.headsign;
          const routeName = departure.tripInstance.trip.route.name.slice(0, 4);
          const modeIcon = departureItemToModeIcon(departure, true);
          return (
            <div
              key={i}
              className={`spi-precinct-departure-item ${i % 2 === 0 ? 'odd' : 'even'}`}
            >
              <div className="display">
                <div className="display-item route">
                  {!modeIcon ? <div
                    className={`route-badge route-len-${routeName.length}`}
                    style={{
                      color: `#${departure.tripInstance.trip.route.textColor}`,
                      backgroundColor: `#${departure.tripInstance.trip.route.color}`
                    }}
                  >
                    {routeName}
                  </div> : <div className="spi-precinct-departure-mode-icon"><img alt="Mode icon" src={modeIcon} /></div>}
                </div>
                <div className="display-item service">
                  <div className="headline">{abbreviateStationName(headsign.headline)}</div>
                  <div className="subtitle-and-mini-capacity">
                    <div className="subtitle-wrapper">
                      <div className="subtitle">{headsign.subtitle}</div>
                    </div>
                    <div className="mini-capacity"></div>
                  </div>
                </div>
                <div className="display-item platform">
                  {departure.stopTimeInstance.stop.disassembled.platformName}
                </div>
                <div className="display-item departs">
                  {renderDepartureTime(
                    departure.stopTimeInstance.departure.time,
                    moment().unix()
                  )}
                </div>
                {departure.stopTimeInstance.ts && (
                  <img
                    alt="Real-time tracked"
                    src="https://anytrip.com.au/img/realtime.svg"
                    className="realtime-icon anytrip-blink"
                  />
                )}
                {capacity && <div className="display-item capacity">
                  {departure.vehicle?.lastPosition?.vehicleOccupancy && (
                    <>
                      <FontAwesomeIcon
                        icon={
                          departure.vehicle?.lastPosition?.vehicleOccupancy >= 1
                            ? fas.faUser
                            : far.faUser
                        }
                      />
                      <FontAwesomeIcon
                        icon={
                          departure.vehicle?.lastPosition?.vehicleOccupancy >= 2
                            ? fas.faUser
                            : far.faUser
                        }
                      />
                      <FontAwesomeIcon
                        icon={
                          departure.vehicle?.lastPosition?.vehicleOccupancy >= 3
                            ? fas.faUser
                            : far.faUser
                        }
                      />
                    </>
                  )}
                </div>}
              </div>
            </div>
          );
        })}
    </>
}