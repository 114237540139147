import React from 'react';
import { SpiScrollingStoppingPattern } from './SpiScrollingStoppingPattern';
import { SpiDepartureRight } from './SpiDepartureRight';
import { SpiService } from '../SpiIndicator';

interface SpiDepartureProps {
    service: SpiService
    hideDepartureRight?: boolean;
    hideLabels?: boolean;
    hidePlatform?: boolean;
    alternativeServices?: boolean;
    dynamic?: boolean;
}
export const SpiDeparture: React.FC<SpiDepartureProps> = ({service, dynamic, hideDepartureRight, hideLabels, hidePlatform, alternativeServices}) => {
    return <div className="spi-departure">
        <SpiScrollingStoppingPattern dynamic={dynamic} alternativeServices={alternativeServices} service={service} />
        {!hideDepartureRight && <SpiDepartureRight hideLabels={hideLabels} hidePlatform={hidePlatform} service={service} />}
    </div>
}