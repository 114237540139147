import React from 'react';
import Moment from 'react-moment';
import { useAs1428 } from '../hooks/useAs1428';

interface SpiHeaderProps {
    header: string;
    color?: string;
    dynamic?: boolean;
}
export const SpiHeader: React.FC<SpiHeaderProps> = ({header, dynamic, color}) => {
    const {getDimension} = useAs1428(55, dynamic || false);
    return <div className="spi-header" style={{...(color ? {backgroundColor: color} : {}), height: getDimension(150)}}>
        <div className="spi-header-wrapper" style={{
                height: getDimension(150),
                paddingLeft: getDimension(80, 'px'),
                paddingRight: getDimension(80, 'px'),
            }}>
            <div className="spi-header-service" style={{
                height: getDimension(70),
                lineHeight: getDimension(70, 'px'),
                fontSize: getDimension(94)
            }}>{header}</div>
            <div className="spi-time-now" style={{
                height: getDimension(70),
            }}>
                <div className="spi-time-now-label" style={{
                    height: getDimension(45),
                    lineHeight: getDimension(45, 'px'),
                    fontSize: getDimension(60)
                }}>Time now</div>
                <div className="spi-time-now-time" style={{
                    height: getDimension(70),
                    lineHeight: getDimension(70, 'px'),
                    fontSize: getDimension(94)
                }}><Moment format="HH:mm:ss" interval={1000} /></div>
            </div>
        </div>
    </div>
}