import React, { useEffect, useRef, useState } from "react"
import ReactSound from "react-sound"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../reducers";
import { useInterval } from "../SpiIndicator/hooks/useInterval";

export const BackgroundTts : React.FC = () => {
    const dispatch = useDispatch();
    const [ttsUrlMemory, setTtsUrlMemory] = useState<string>();
    const [playStatus, setPlayStatus] = useState<any>("PLAYING");
    // const ttsUrl = useSelector((state: any) => state.tts.ttsUrl);
    const {audioMaxFrequency, audioWaitBeforeRepeating, ttsUrl} = useSelector((state: any) => state.tts);

    const lastPlayed = useRef<number>();
    const lastPlayedByUrl = useRef<{[k: string]: number}>({});

    const finishedPlaying = () => {
        lastPlayed.current = new Date().valueOf();
        lastPlayedByUrl.current = {...(lastPlayedByUrl.current||{}), [ttsUrl]: lastPlayed.current};
        dispatch(actions.ttsFinished())
        dispatch(actions.resetTtsUrl())
        setPlayStatus("STOPPED")
    }

    useEffect(() => {
        if(ttsUrl && ttsUrl !== ttsUrlMemory){
            console.log(`Setting memory ${ttsUrl}`, {ttsUrl, ttsUrlMemory})
            setTtsUrlMemory(ttsUrl);
        }
    }, [ttsUrl, ttsUrlMemory])

    useInterval(() => {
        const canPlayIfLastPlayIsAfter = new Date().valueOf() - (audioMaxFrequency||60)*1000;
        const canPlayIfLastPlayOfUrlIsAfter = new Date().valueOf() - (audioWaitBeforeRepeating||60*5)*1000;
        if(
            playStatus !== "PLAYING" &&
            lastPlayed.current && lastPlayed.current < canPlayIfLastPlayIsAfter &&
            (!lastPlayedByUrl.current[ttsUrl] || lastPlayedByUrl.current[ttsUrl] < canPlayIfLastPlayOfUrlIsAfter)
        ){
            setPlayStatus("PLAYING");
            delete lastPlayedByUrl.current[ttsUrl];
            lastPlayed.current = undefined
        }
    }, 500, true);

    // return null;

    return <>
        {ttsUrlMemory && playStatus !== 'STOPPED' ? <ReactSound url={ttsUrlMemory} playStatus={playStatus} onFinishedPlaying={finishedPlaying} onError={finishedPlaying} /> : null}
    </>
}