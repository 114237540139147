import React from 'react';

interface SpiLabelProps {
    text: string;
    style?: React.CSSProperties;
}
export const SpiLabel: React.FC<SpiLabelProps> = ({text, style}) => {
    return <div className="spi-label" style={style}>
        <div className="spi-label-wrapper">
            {text}
        </div>
    </div>
}