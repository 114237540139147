import React from "react";
import { SpiHeader, SpiContainer, SpiPrecinctDepartures } from "../components";
import { IDepartureItem } from "../../../reducers/anytrip-api.types";

interface SpiPrecinctDeparturesViewProps {
  precinctDepartures: IDepartureItem[];
}
export const SpiPrecinctDeparturesViewView: React.FC<SpiPrecinctDeparturesViewProps> = ({
  precinctDepartures
}) => {
  return (
    <>
      <SpiHeader header="Precinct departures" />
      <SpiContainer classNames={["spi-precinct-departures"]}>
        <SpiPrecinctDepartures precinctDepartures={precinctDepartures} />
      </SpiContainer>
    </>
  );
};
