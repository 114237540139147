import { createAction, handleActions } from 'redux-actions';
import { ITripInstance, IRealtimeStopTime, IApiRequestTripInstanceResponse, IApiRequestDeparturesResponse, IApiHeaderResponse } from './anytrip-api.types';

const types = {
  SET_TRIP_INSTANCE: 'SET_TRIP_INSTANCE',
  RECEIVE_TRIP_INSTANCE_DATA: 'RECEIVE_TRIP_INSTANCE_DATA',
  RECEIVE_SCREEN_STATE: 'RECEIVE_SCREEN_STATE',
  CHECK_SCREEN_STATE: 'CHECK_SCREEN_STATE',

  RECEIVE_INTERCHANGE_DEPARTURES: 'RECEIVE_INTERCHANGE_DEPARTURES',

  SCREEN_MODE_BEGIN: 'SCREEN_MODE_BEGIN',
  SCREEN_MODE_START: 'SCREEN_MODE_START',
  SCREEN_MODE_END: 'SCREEN_MODE_END',
  SCREEN_MODE_CANCEL: 'SCREEN_MODE_CANCEL',

  DISPLAY_BEGIN: 'DISPLAY_BEGIN',
  DISPLAY_START: 'DISPLAY_START',
  DISPLAY_END: 'DISPLAY_END',
  DISPLAY_CANCEL: 'DISPLAY_CANCEL',

  ANNOUNCE_START: 'ANNOUNCE_START',
  ANNOUNCE_END: 'ANNOUNCE_END',
};

export interface TripInstanceIdentifier {
  rtTripId: string;
  startDate: string;
  instanceNumber: number;
  region?: string;
}

export enum TripState {
  AT_STOP = 'AT_STOP',
  IN_TRANSIT_TO = 'IN_TRANSIT_TO',
  APPROACHING = 'APPROACHING'
}

export enum ScreenModes {
  BLANK = 'BLANK',
  AT_STOP = 'AT_STOP',
  DEPARTURE = 'DEPARTURE',
  EN_ROUTE_A = 'EN_ROUTE_A',
  MID_ROUTE = 'MID_ROUTE',
  MID_ROUTE_NON_HIJACK = 'MID_ROUTE_NON_HIJACK',
  EN_ROUTE_B = 'EN_ROUTE_B',
  APPROACH = 'APPROACH',
  APPROACH_REPEAT = 'APPROACH_REPEAT'
}

// enum DoorOpeningSide {
//   LEFT_IN_DIRECTION_OF_TRAVEL,
//   RIGHT_IN_DIRECTION_OF_TRAVEL
// }

export enum IdiDisplayType {
  DOORS_OPENING = 'DOORS_OPENING',
  DESTINATION = 'DESTINATION',
  STOPPING_PATTERN = 'STOPPING_PATTERN',
  STOP_CONNECTIONS = 'STOP_CONNECTIONS',
  STOP_ALERT = 'STOP_ALERT',
  STOPPAGE = 'STOPPAGE',
  MESSAGE = 'MESSAGE',
  CAPACITY = 'CAPACITY',
  FACILITIES_A = 'FACILITIES_A',
  FACILITIES_B = 'FACILITIES_B',
  MULTIMEDIA = 'MULTIMEDIA',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  BLANK = 'BLANK'
}

export interface IdiDisplay {
  displayType: IdiDisplayType;
  minDisplaySeconds?: number;
}

interface IdiScreenState {
  mode: ScreenModes;
  surroundingStops?: {
    at?: IRealtimeStopTime;
    next?: IRealtimeStopTime;
    prev?: IRealtimeStopTime;
  };
  tripInstance?: ITripInstance;
  remainingStoppingPattern?: IRealtimeStopTime[];
}

export interface IdiReducerState {
  selectedTripInstance?: TripInstanceIdentifier;
  tripInstanceData?: IApiRequestTripInstanceResponse;
  interchangeDeparturesData?: IApiHeaderResponse<IApiRequestDeparturesResponse>;

  screenState?: IdiScreenState;
  currentDisplay?: IdiDisplay;
  currentDisplayIndex?: number;
}

const initialState: IdiReducerState = {};

export const reducer = handleActions<IdiReducerState>(
  {
    [types.SET_TRIP_INSTANCE]: (state, action) => {
      const { selectedTripInstance } = action.payload;
      return {
        ...state,
        selectedTripInstance
      };
    },
    [types.RECEIVE_TRIP_INSTANCE_DATA]: (state, action) => {
      const { tripInstanceData } = action.payload;
      return {
        ...state,
        tripInstanceData
      };
    },
    [types.RECEIVE_SCREEN_STATE]: (state, action) => {
      const { screenState, resetDisplay } = action.payload as any;
      return {
        ...state,
        screenState,
        ...(resetDisplay && {
          currentDisplay: undefined,
          currentDisplayIndex: undefined
        })
      };
    },

    [types.RECEIVE_INTERCHANGE_DEPARTURES]: (state, action) => {
      const { interchangeDeparturesData } = action.payload as any;
      return {
        ...state,
        interchangeDeparturesData
      };
    },

    [types.DISPLAY_START]: (state, action) => {
      const { currentDisplay, currentDisplayIndex } = action.payload;
      return {
        ...state,
        currentDisplayIndex,
        currentDisplay
      };
    },
  },
  initialState
);

export interface RecieveTripInstanceDataPayload {
  tripInstanceData: any;
}

export interface SetTripInstancePayload {
  selectedTripInstance: TripInstanceIdentifier;
}

export interface RecieveScreenStatePayload {
  screenState: IdiScreenState;
  resetDisplay?: boolean;
}

export interface DisplayStartPayload {
  currentDisplay: IdiDisplay;
  currentDisplayIndex: number;
}

export const actions = {
  setTripInstance: createAction<SetTripInstancePayload>(types.SET_TRIP_INSTANCE),
  receiveTripInstanceData: createAction<RecieveTripInstanceDataPayload>(types.RECEIVE_TRIP_INSTANCE_DATA),
  receiveScreenState: createAction<RecieveScreenStatePayload>(types.RECEIVE_SCREEN_STATE),
  checkScreenState: createAction(types.CHECK_SCREEN_STATE),

  receiveInterchangeDepartures: createAction<Pick<IdiReducerState, 'interchangeDeparturesData'>>(types.RECEIVE_INTERCHANGE_DEPARTURES),

  displayBegin: createAction(types.DISPLAY_BEGIN),
  displayStart: createAction<DisplayStartPayload>(types.DISPLAY_START),
  displayEnd: createAction(types.DISPLAY_END),
  displayCancel: createAction(types.DISPLAY_CANCEL),

  screenModeBegin: createAction(types.SCREEN_MODE_BEGIN),
  screenModeStart: createAction(types.SCREEN_MODE_START),
  screenModeEnd: createAction(types.SCREEN_MODE_END),
  screenModeCancel: createAction(types.SCREEN_MODE_CANCEL),

  announceStart: createAction(types.ANNOUNCE_START),
  announceEnd: createAction(types.ANNOUNCE_END),
};

export const idi = {
  actions,
  types,
  reducer,
};
