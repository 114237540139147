import React from 'react';

interface SpiOccupancyProps {
    occupancy?: number[]
}
export const SpiOccupancy: React.FC<SpiOccupancyProps> = () => {
    const occupancy = [0,1,2,1,2,0,1,2];
    const occupancyToClass = (n: number) => {
        if(n === 1){ return 'car orange' }
        if(n === 2){ return 'car red' }
        return 'car'
    }
    return <div className="spi-occupancy">
        <div className="spi-occupancy-wrapper">
            <div className="spi-occupancy-car-numbers">
                {occupancy.map((o, i, a) => <div className="car-number" key={i}>{a.length - i}</div>)}
            </div>
            <div className="spi-occupancy-cars">
                {occupancy.map((o, i) => <div className={occupancyToClass(o)} key={i}></div>)}
            </div>
        </div>
    </div>
}