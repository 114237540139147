import React, { useState } from 'react';
import { SpiHeader, SpiHeadsign, SpiContainer, SpiDeparture, SpiOccupancy, SpiBanner, SpiAlternativeDepartureLocation, SpiPrecinctDepartures } from '../components';
import { SpiService } from '../SpiIndicator';
import { useInterval } from '../hooks/useInterval';
import { IDepartureItem } from '../../../reducers/anytrip-api.types';

interface SpiLandscapeSplitView {
    services: SpiService[];
    alternativeServices?: boolean;
    precinctDepartures?: IDepartureItem[]
    overrideScreenNumber?: number;
}
export const SpiLandscapeSplitView: React.FC<SpiLandscapeSplitView> = ({services, alternativeServices, precinctDepartures, overrideScreenNumber}) => {
    const [proxyScreenNumber, setScreenNumber] = useState(0);

    const screenNumber = overrideScreenNumber != null ? overrideScreenNumber : proxyScreenNumber

    useInterval(() => {
        const screens = Math.ceil(services.length/2);
        setScreenNumber((screenNumber+1) % screens);
    }, 15000);

    const firstService = (alternativeServices ? services[screenNumber*2] : services[0]);
    const secondService = (alternativeServices ? services[screenNumber*2 + 1] : services[1]);
    return <>
        <SpiHeader dynamic={true} header={alternativeServices ? "Alternative services" : 'Next service'} color={alternativeServices ? '#00b5ef' : undefined} />
        
        <SpiContainer split style={{flex: 1, minHeight: 0}} classNames={[alternativeServices ? 'alternative-services' : 'next-services']}>
            {firstService ? <div className="spi-landscape">
                {alternativeServices ? <SpiAlternativeDepartureLocation service={firstService} /> : 
                <SpiHeadsign
                    hidePlatform={true}
                    service={firstService}
                    dynamic={true}
                />}
                {firstService.occupancy && <SpiOccupancy />}
                <SpiContainer style={{marginTop: 20}}>
                    <div className="spi-divider" />
                </SpiContainer>
                <SpiDeparture dynamic={true} hideLabels hidePlatform={alternativeServices} alternativeServices={alternativeServices} service={firstService} />
            </div> : <div className="spi-landscape"></div>}
            {
                precinctDepartures && (screenNumber === 0 || !secondService) ? <div className="spi-landscape spi-precinct-departures">
                    <SpiPrecinctDepartures capacity={false} precinctDepartures={precinctDepartures} />
                </div> : 
                secondService ? <div className="spi-landscape">
                    {alternativeServices ? <SpiAlternativeDepartureLocation service={secondService} /> : 
                    <SpiHeadsign
                        hidePlatform={true}
                        service={secondService}
                    />}
                    {secondService.occupancy && <SpiOccupancy />}
                    <SpiContainer style={{marginTop: 20}}>
                        <div className="spi-divider" />
                    </SpiContainer>
                    <SpiDeparture hideLabels hidePlatform={alternativeServices} alternativeServices={alternativeServices} service={secondService} />
                </div> : <div className="spi-landscape"></div>
            }
            {/* {secondService ? <div className="spi-landscape spi-precinct-departures">
                {precinctDepartures ? <SpiPrecinctDepartures capacity={false} precinctDepartures={precinctDepartures} /> : 
                alternativeServices ? <SpiAlternativeDepartureLocation service={secondService} /> : 
                <SpiHeadsign
                    hidePlatform={true}
                    service={secondService}
                />}
                {secondService.occupancy && <SpiOccupancy />}
                <SpiContainer style={{marginTop: 20}}>
                    <div className="spi-divider" />
                </SpiContainer>
                <SpiDeparture hideLabels hidePlatform={alternativeServices} alternativeServices={alternativeServices} service={secondService} />
            </div> : <div className="spi-landscape"></div>} */}
        </SpiContainer>
        {alternativeServices && <SpiBanner>
            <div>Only services and stops near stations are shown.  Check <b>transportnsw.info</b> or real-time apps for full service timetable.</div>
        </SpiBanner>}
    </>
}