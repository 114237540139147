import React from 'react';
import { SpiHeader, SpiHeadsign, SpiOccupancy, SpiContainer, SpiDeparture } from '../components';
import { SpiService } from '../SpiIndicator';
// import moment from 'moment';

interface SpiLandscapeNextServiceProps {
    service: SpiService
}

export const SpiLandscapeNextService: React.FC<SpiLandscapeNextServiceProps> = ({service}) => {
    // return service.cancelled ? <>
    //     <SpiCustomerServiceMessage alert={{
    //         header: 'Service cancelled',
    //         description: `The ${moment.unix(service.scheduledDepartureTime).format('HH:mm')} service to ${service.headsignDestination} has been cancelled for today.`
    //     }} />
    // </> : 
    return <>
        <SpiHeader header="Next service" />
        <SpiHeadsign
            service={service}
        />
        {service.occupancy && <SpiOccupancy />}
        <SpiContainer style={{marginTop: 40}}>
            <div className="spi-divider" />
        </SpiContainer>
        <SpiDeparture hidePlatform service={service} />
    </>
}