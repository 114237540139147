import React from 'react';
import { SpiService } from '../SpiIndicator';
import { SpiContainer } from './SpiContainer';

interface SpiAlternativeDepartureLocationProps {
    service: SpiService;
}
export const SpiAlternativeDepartureLocation: React.FC<SpiAlternativeDepartureLocationProps> = ({service}) => {
    return <SpiContainer>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 35}}>
            {
                service.modeIcon ?
                <div className="spi-headsign-mode-icon"><img src={service.modeIcon} alt="Mode icon" /></div> : null
            }
            <div className="spi-departure-location">
                <div className="spi-departure-station">{service.routeName}</div>
                <div className="spi-departure-platform">{service.headsignDestination.substr(0, 20)}</div>
            </div>
        </div>
    </SpiContainer>
}