import { useSelector } from "react-redux";
import { SpiReducerState } from "../../../reducers/spi.reducer";

export const useAs1428 = (screenMinFontSize: number, enabled?: boolean) => {
    const { screenDimensions }: SpiReducerState = useSelector(
        (state: any) => state.spi
    );

    let screenSizeDiagMillimeters = (screenDimensions?.diagonalInches || 32) * 25.4; // convert to mm
    let screenAspectRatio = 9/16;
    let screenVerticalPixels = 1920;
    let minFontHeightMillimeters = 12; 
    let screenSizeVerticalMillimeters = Math.sqrt(Math.pow(screenSizeDiagMillimeters, 2) / (1+Math.pow(screenAspectRatio, 2)));

    let verticalPixelsPerMillimeter = screenVerticalPixels/screenSizeVerticalMillimeters;
    let minFontHeightPixels = verticalPixelsPerMillimeter * minFontHeightMillimeters;

    function getDimension (px: number): number
    function getDimension (px: number, suffix: string): string
    function getDimension (px: number, suffix?: string) {
        if(enabled === false){
            if(suffix != null){
                return `${px}${suffix}`
            }
            return px
        }

        const val =  px / screenMinFontSize * minFontHeightPixels;
        if(suffix != null){
            return `${val}${suffix}`
        }

        return val;
    }

    // function getDynamicDimension (enabled: boolean, px: number): number
    // function getDynamicDimension (enabled: boolean, px: number, suffix: string): string
    // function getDynamicDimension (enabled: boolean, px: number, suffix?: string) {
    //     if(!enabled){
    //         if(suffix != null){
    //             return `${px}${suffix}`
    //         }
    //         return px
    //     }
    //     return getDimension(px, suffix as any);
    // }
    // const getDimension : (((px: number) => number) | ((px: number, suffix: string) => string)) = (px: number, suffix?: string) => {
    //     const val =  px / screenMinFontSize * minFontHeightPixels;
    //     if(suffix != null){
    //         return `${val}${suffix}`
    //     }

    //     return val;
    // }
    
    //Math.sqrt(Math.pow(screenSizeDiagMillimeters, 2) - Math.pow(screenAspectRatio * , 2))
    // useMemo()

    return {getDimension}
}