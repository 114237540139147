import { combineReducers } from 'redux';
import { idi } from './idi.reducer';
import { tts } from './tts.reducer';
import { spi } from './spi.reducer';

export const reducers = {
  idi: idi.reducer,
  tts: tts.reducer,
  spi: spi.reducer
};

export const actions = {
  ...idi.actions,
  ...tts.actions,
  ...spi.actions
};

export const actionTypes = {
  ...idi.types,
  ...tts.types,
  ...spi.types
};

export const rootReducer = combineReducers(reducers);