import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../reducers';
import { IAlert } from '../../../reducers/anytrip-api.types';

interface SpiCustomerServiceMessageProps {
    alert: Pick<IAlert, 'description' | 'header'>;
    // children: React.ReactChild;
    // style?: React.CSSProperties;
}

const cleanLine = (line: string) => {
    return line.replace(/[\s.]+$/, '').replace(/^[\s.]+/, '');
}

const alertTransformer = (alert: Pick<IAlert, 'description' | 'header'>) => {
    const paras = [];

    if (alert.header) {
        paras.push(cleanLine(alert.header));
    }

    if (alert.description) {
        const desc = alert.description.split(/[\n\r]+/);
        for (const line of desc) {
            const cl = cleanLine(line);
            if (cl !== paras[0]) {
                paras.push(cl)
            }
        }
    }

    return paras.map((line, i) => <p key={i}>{line.split('').map((s, ii) => <span key={ii} className={'dt'}>{s}</span>)}</p>)

}
export const DotDisruption: React.FC<SpiCustomerServiceMessageProps> = ({ alert }) => {
    const [secondsToScroll, setSecondsToScroll] = useState(0);
    const scrollingContainerEl: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const dispatch = useDispatch();

    const listener = () => {
        if (scrollingContainerEl && scrollingContainerEl.current) {
            const height = scrollingContainerEl.current.clientHeight + 10;
            // console.log({height});
            // const spans = scrollingContainerEl.current.querySelectorAll('span.dt');
            // console.log({height, spans});
            const stopEl = scrollingContainerEl.current.querySelector('.dot-disrupt-content');
            const textHeight = (stopEl ? stopEl.clientHeight : 0) - 50;

            if (textHeight > height) {
                const sts = ((textHeight + 50) / 112) * 3;
                if (sts !== secondsToScroll) {
                    setSecondsToScroll(sts);
                }
            } else {
                setSecondsToScroll(0)
            }

            // // stationsToScroll * 
            console.log({height, textHeight, secondsToScroll});
        }
    };

    useEffect(() => {
        if (secondsToScroll > 0) {
            dispatch(actions.setRotationProperties({
                rotationProperties: {
                    minDuration: secondsToScroll + 5
                }
            }))
        } else {
            dispatch(actions.setRotationProperties({}))
        }
    }, [dispatch, secondsToScroll]);

    useEffect(() => {
        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        listener();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollingContainerEl, alert.description, alert.header]);

    const transformedAlert = useMemo(() => {
        return alertTransformer(alert)
    }, [alert])

    return <div style={{ padding: 22, color: 'black', fontSize: 24, flex: 1, zIndex: 99 }}>
        <div style={{ overflow: 'hidden', maxHeight: 216 }} ref={scrollingContainerEl}>
            {alert && <div className="dot-disrupt-body" style={secondsToScroll ? {
                animationDelay: (secondsToScroll > 0 ? '5000ms' : '0ms'),
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                animationDuration: `${secondsToScroll * 1000}ms`,
                animationName: 'dot-disrupt-scroll'
            } : {}} key={secondsToScroll}>
                <div className="dot-disrupt-content" style={{paddingBottom: 50}}>
                    <div style={{ fontWeight: 'bold', marginBottom: 12 }}>
                        {alert.header}
                    </div>

                    {alert.description !== alert.header && <div>
                        {alert.description}
                    </div>}
                </div>
                {secondsToScroll ? <div className="dot-disrupt-content" style={{paddingBottom: 50}}>
                    <div style={{ fontWeight: 'bold', marginBottom: 12 }}>
                        {alert.header}
                    </div>

                    {alert.description !== alert.header && <div>
                        {alert.description}
                    </div>}
                </div> : null}
            </div>}
            {/* <div style={{ fontWeight: 'bold', marginBottom: 12 }}>
                {alert.header}
            </div>

            <div>
                {alert.description}
            </div> */}
        </div>
    </div>

    return <div className="spi-csm">
        <div className="spi-csm-wrapper">
            <div className="spi-csm-header">
                <h1>Service message</h1>
            </div>
            <div className="spi-csm-abs" ref={scrollingContainerEl}>
                {/* <div className="spi-csm-body">
                    <div className="spi-csm-body-content">{alertTransformer(alert)}</div>
                </div> */}
                {alert && <div className="spi-csm-body" style={secondsToScroll ? {
                    animationDelay: (secondsToScroll > 0 ? '5000ms' : '0ms'),
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear',
                    animationDuration: `${secondsToScroll * 1000}ms`,
                    animationName: 'spi-stopping-pattern-scroll'
                } : {}} key={secondsToScroll}>
                    <div className="spi-csm-body-content">{transformedAlert}</div>
                    {secondsToScroll ? <div className="spi-csm-body-content">{transformedAlert}</div> : null}
                </div>}
            </div>
            <div className="spi-csm-tni">
                Visit <strong>transportnsw.info</strong> for service updates or to plan your trip.
            </div>
        </div>
    </div>
}