import { SHORT_PLATFORM_INTERCHANGE_MODES } from "./ref-data.const"

export const getInterchangeModes = (stopId: string) => {
    const spim = SHORT_PLATFORM_INTERCHANGE_MODES[stopId];
    
    return spim
}

export const getModeIconFromString = (modeString: string) => {
    if(modeString === 'Train'){
        return "https://anytrip.com.au/img/tfnsw/train.svg"
    }else if(modeString === 'Bus'){
        return "https://anytrip.com.au/img/tfnsw/bus.svg"
    }else if(modeString === 'Light Rail'){
        return "https://anytrip.com.au/img/tfnsw/lightrail.svg"
    }else if(modeString === 'Coach'){
        return "https://anytrip.com.au/img/tfnsw/coach.svg"
    }else if(modeString === 'Regional coach'){
        return "https://anytrip.com.au/img/tfnsw/coach.svg"
    }else if(modeString === 'Ferry'){
        return "https://anytrip.com.au/img/tfnsw/ferry.svg"
    }else if(modeString === 'Metro'){
        return "https://anytrip.com.au/img/tfnsw/metro.svg"
    }

    if(modeString === 'au2:sydneytrains' || modeString === 'au2:nswtrains'){
        return "https://anytrip.com.au/img/tfnsw/train.svg"
    }else if(modeString === 'au2:buses' || modeString === 'au2:privatebuses' || modeString === 'au2:tempbuses'){
        return "https://anytrip.com.au/img/tfnsw/bus.svg"
    }else if(modeString === 'au2:schoolbuses'){
        return "https://anytrip.com.au/img/tfnsw/bus-school.svg"
    }else if(modeString === 'au2:lightrail'){
        return "https://anytrip.com.au/img/tfnsw/lightrail.svg"
    }else if(modeString === 'au2:nswcoaches'){
        return "https://anytrip.com.au/img/tfnsw/coach.svg"
    }else if(modeString === 'au2:ferries'){
        return "https://anytrip.com.au/img/tfnsw/ferry.svg"
    }else if(modeString === 'au2:metro'){
        return "https://anytrip.com.au/img/tfnsw/metro.svg"
    }

    return "https://anytrip.com.au/img/tfnsw/stop.svg"
    // <img src="https://anytrip.com.au/img/tfnsw/train.svg" />
    // <img src="https://anytrip.com.au/img/tfnsw/bus.svg" />
    // <img src="https://anytrip.com.au/img/tfnsw/lightrail.svg" />
}

export const getModeNameFromString = (modeString: string) => {
    if(modeString === 'Train'){
        return 'Trains'
    }else if(modeString === 'Bus'){
        return 'Buses'
    }else if(modeString === 'Light Rail'){
        return 'Light Rail'
    }else if(modeString === 'Coach'){
        return 'Coaches'
    }else if(modeString === 'Regional coach'){
        return 'Coaches'
    }else if(modeString === 'Ferry'){
        return 'Ferries'
    }else if(modeString === 'Metro'){
        return 'Metro'
    }

    if(modeString === 'au2:sydneytrains' || modeString === 'au2:nswtrains'){
        return 'Trains'
    }else if(modeString === 'au2:buses' || modeString === 'au2:privatebuses' || modeString === 'au2:tempbuses'){
        return 'Buses'
    }else if(modeString === 'au2:schoolbuses'){
        return 'Buses'
    }else if(modeString === 'au2:lightrail'){
        return 'Light Rail'
    }else if(modeString === 'au2:nswcoaches'){
        return 'Coaches'
    }else if(modeString === 'au2:ferries'){
        return 'Ferries'
    }else if(modeString === 'au2:metro'){
        return 'Metro'
    }

    return 'Other modes'
    // <img src="https://anytrip.com.au/img/tfnsw/train.svg" />
    // <img src="https://anytrip.com.au/img/tfnsw/bus.svg" />
    // <img src="https://anytrip.com.au/img/tfnsw/lightrail.svg" />
}