import React from 'react';

interface SpiContainerProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;

    split?: boolean;
    classNames?: string[]
}
export const SpiContainer: React.FC<SpiContainerProps> = ({children, style, wrapperStyle, split, classNames}) => {
    const className = ['spi-container'].concat(split ? ['spi-container-split'] : [], classNames||[]).join(' ');
    return <div className={className} style={style}>
        <div className="spi-container-wrapper" style={wrapperStyle}>
            {children}
        </div>
    </div>
}