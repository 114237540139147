import React from 'react';
import { SpiService } from '../SpiIndicator';
import moment from 'moment';
import { abbreviateStationName } from '../../../utils';
import { SpiLabel } from './SpiLabel';
// import { useAs1428 } from '../hooks/useAs1428';

interface SpiHeadsignProps {
    service: SpiService;
    hidePlatform?: boolean;
    dynamic?: boolean;
}
export const SpiHeadsign: React.FC<SpiHeadsignProps> = ({service, dynamic, hidePlatform}) => {
    // const {getDimension} = useAs1428(55, dynamic || false);
    return <div className="spi-headsign">
        <div className="spi-headsign-wrapper">
            {
                service.modeIcon ?
                <div className="spi-headsign-mode-icon"><img src={service.modeIcon} alt="Mode icon" /></div> :
                <div className={`spi-route-badge spi-route-badge-len-${service.routeName.length}`} style={{
                    backgroundColor: `#${service.routeColor}`,
                    color: `#${service.routeTextColor}`
                }}><div className="spi-route-badge-wrapper">{service.routeName}</div></div>
            }
            {service.intercityRegionalLayout ? <div className="spi-headsign-text spi-headsign-text-intercity">
                <div className="spi-headsign-intercity-time">
                    {
                        service.modeIcon &&
                        <div className="spi-split-screen-headsign-mode-icon"><img alt="Mode icon" src={service.modeIcon} /></div>
                    }
                    <span>
                        {moment.unix(service.scheduledDepartureTime).format('HH:mm')}
                    </span>
                </div>
                <div className="spi-headsign-intercity-destination">
                    {abbreviateStationName(service.headsignDestination)}{service.headsignWaypoint && <span className="spi-headsign-intercity-waypoint"> {service.headsignWaypoint}</span>}
                </div>
                <div className="spi-headsign-badge">
                    {service.isBookingRequired ? <SpiLabel text="Booked seats only" style={{background: '#E65010', minWidth: 500}} /> : <SpiLabel text={service.serviceType} />}
                </div>
            </div> : <div className="spi-headsign-text">
                <div className="spi-headsign-destination">
                    {abbreviateStationName(service.headsignDestination)}
                </div>
                {service.headsignWaypoint && <div className="spi-headsign-waypoint">
                    {service.headsignWaypoint}
                </div>}
            </div>}
            {!hidePlatform && <div className="spi-headsign-platform">
                <div className="spi-headsign-platform-label">{service.platformType}</div>
                <div className="spi-headsign-platform-number">{service.platformNumber}</div>
            </div>}
        </div>
    </div>
}