import { createAction, handleActions } from 'redux-actions';
// import { ITripInstance, IRealtimeStopTime, IApiRequestTripInstanceResponse } from './anytrip-api.types';

const types = {
  SET_SCREEN_DIMENSIONS: 'SET_SCREEN_DIMENSIONS',
  SET_ROTATION_PROPERTIES: 'SET_ROTATION_PROPERTIES',
  // SET_TRIP_INSTANCE: 'SET_TRIP_INSTANCE',
  // RECEIVE_TRIP_INSTANCE_DATA: 'RECEIVE_TRIP_INSTANCE_DATA',
  // RECEIVE_SCREEN_STATE: 'RECEIVE_SCREEN_STATE',
  // CHECK_SCREEN_STATE: 'CHECK_SCREEN_STATE',

  // SCREEN_MODE_BEGIN: 'SCREEN_MODE_BEGIN',
  // SCREEN_MODE_START: 'SCREEN_MODE_START',
  // SCREEN_MODE_END: 'SCREEN_MODE_END',
  // SCREEN_MODE_CANCEL: 'SCREEN_MODE_CANCEL',

  // DISPLAY_BEGIN: 'DISPLAY_BEGIN',
  // DISPLAY_START: 'DISPLAY_START',
  // DISPLAY_END: 'DISPLAY_END',
  // DISPLAY_CANCEL: 'DISPLAY_CANCEL',

  // ANNOUNCE_START: 'ANNOUNCE_START',
  // ANNOUNCE_END: 'ANNOUNCE_END',
};

// export interface TripInstanceIdentifier {
//   rtTripId: string;
//   startDate: string;
//   instanceNumber: number;
//   region?: string;
// }

// export enum TripState {
//   AT_STOP = 'AT_STOP',
//   IN_TRANSIT_TO = 'IN_TRANSIT_TO',
//   APPROACHING = 'APPROACHING'
// }

// export enum ScreenModes {
//   BLANK = 'BLANK',
//   AT_STOP = 'AT_STOP',
//   DEPARTURE = 'DEPARTURE',
//   EN_ROUTE_A = 'EN_ROUTE_A',
//   MID_ROUTE = 'MID_ROUTE',
//   MID_ROUTE_NON_HIJACK = 'MID_ROUTE_NON_HIJACK',
//   EN_ROUTE_B = 'EN_ROUTE_B',
//   APPROACH = 'APPROACH',
//   APPROACH_REPEAT = 'APPROACH_REPEAT'
// }

// // enum DoorOpeningSide {
// //   LEFT_IN_DIRECTION_OF_TRAVEL,
// //   RIGHT_IN_DIRECTION_OF_TRAVEL
// // }

// export enum IdiDisplayType {
//   DOORS_OPENING = 'DOORS_OPENING',
//   DESTINATION = 'DESTINATION',
//   STOPPING_PATTERN = 'STOPPING_PATTERN',
//   STOP_CONNECTIONS = 'STOP_CONNECTIONS',
//   STOP_ALERT = 'STOP_ALERT',
//   STOPPAGE = 'STOPPAGE',
//   MESSAGE = 'MESSAGE',
//   CAPACITY = 'CAPACITY',
//   FACILITIES_A = 'FACILITIES_A',
//   FACILITIES_B = 'FACILITIES_B',
//   MULTIMEDIA = 'MULTIMEDIA',
//   ANNOUNCEMENT = 'ANNOUNCEMENT',
//   BLANK = 'BLANK'
// }

// // export interface IdiDisplay {
// //   displayType: IdiDisplayType;
// //   minDisplaySeconds?: number;
// // }

// // interface IdiScreenState {
// //   mode: ScreenModes;
// //   surroundingStops?: {
// //     at?: IRealtimeStopTime;
// //     next?: IRealtimeStopTime;
// //     prev?: IRealtimeStopTime;
// //   };
// //   tripInstance?: ITripInstance;
// //   remainingStoppingPattern?: IRealtimeStopTime[];
// // }

export interface RotationProperties {
  pages?: number;
  minDuration?: number;
}
export interface SpiReducerState {
  screenDimensions?: ScreenDimensions;
  rotationProperties?: RotationProperties;

  // selectedTripInstance?: TripInstanceIdentifier;
  // tripInstanceData?: IApiRequestTripInstanceResponse;

  // screenState?: IdiScreenState;
  // currentDisplay?: IdiDisplay;
  // currentDisplayIndex?: number;
}

const initialState: SpiReducerState = {};

export const reducer = handleActions<SpiReducerState>(
  {
    [types.SET_SCREEN_DIMENSIONS]: (state, action) => {
      const { screenDimensions } = action.payload;
      return {
        ...state,
        screenDimensions
      };
    },
    [types.SET_ROTATION_PROPERTIES]: (state, action) => {
      const { rotationProperties } = action.payload;
      if(rotationProperties == null){
        return {
          ...state,
          rotationProperties: undefined
        }
      }

      return {
        ...state,
        rotationProperties
      };
    },
  },
  initialState
);

// export interface RecieveTripInstanceDataPayload {
//   tripInstanceData: any;
// }

// export interface SetTripInstancePayload {
//   selectedTripInstance: TripInstanceIdentifier;
// }

// export interface RecieveScreenStatePayload {
//   screenState: IdiScreenState;
//   resetDisplay?: boolean;
// }

// export interface DisplayStartPayload {
//   currentDisplay: IdiDisplay;
//   currentDisplayIndex: number;
// }

export interface ScreenDimensions {
  diagonalInches: number;
}

export const actions = {
  setScreenDimensions: createAction<{screenDimensions: ScreenDimensions}>(types.SET_SCREEN_DIMENSIONS),
  setRotationProperties: createAction<{rotationProperties?: RotationProperties}>(types.SET_ROTATION_PROPERTIES),
  // setTripInstance: createAction<SetTripInstancePayload>(types.SET_TRIP_INSTANCE),
  // receiveTripInstanceData: createAction<RecieveTripInstanceDataPayload>(types.RECEIVE_TRIP_INSTANCE_DATA),
  // receiveScreenState: createAction<RecieveScreenStatePayload>(types.RECEIVE_SCREEN_STATE),
  // checkScreenState: createAction(types.CHECK_SCREEN_STATE),

  // displayBegin: createAction(types.DISPLAY_BEGIN),
  // displayStart: createAction<DisplayStartPayload>(types.DISPLAY_START),
  // displayEnd: createAction(types.DISPLAY_END),
  // displayCancel: createAction(types.DISPLAY_CANCEL),

  // screenModeBegin: createAction(types.SCREEN_MODE_BEGIN),
  // screenModeStart: createAction(types.SCREEN_MODE_START),
  // screenModeEnd: createAction(types.SCREEN_MODE_END),
  // screenModeCancel: createAction(types.SCREEN_MODE_CANCEL),

  // announceStart: createAction(types.ANNOUNCE_START),
  // announceEnd: createAction(types.ANNOUNCE_END),
};

export const spi = {
  actions,
  types,
  reducer,
};
