import React, { useEffect, useMemo, useState } from "react";
import { IAlert, IPrecinctStop } from "../../../reducers/anytrip-api.types";
import { PlrModeHeader } from "../components/PlrModeHeader";
import { PlrDepartureItems } from "../components/PlrDepartureItems";
import { getModeIconFromString, getModeNameFromString } from "../../../utils";
import { useAs1428 } from "../hooks/useAs1428";
import moment from "moment";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers";
import { useInterval } from "../hooks/useInterval";
import { DepartureTimeMode, renderDepartureTime } from "../common/utils";
import Moment from "react-moment";
import useSWR from "swr";
import Axios from "axios";
import { DotDisruption } from "../components/DotDisruption";

const WALK_CONFIG: { [k: string]: { time: number, direction: number } } = {
    'Sunshine Station/Dickson St': {
        time: 3,
        direction: 45
    },
    "City Pl/Sun Cres": {
        time: 4,
        direction: 0
    }
}

const fetcher = (url: string) => Axios.get(url).then(res => res.data)

const RIDE_SPACE_ROUTES = ['190', '402', '404', '420', '431', '432', '471', '498', '552', '601', '623'];

const RealTimeWave = () => {
    return <div style={{ width: 0, display: 'inline-block' }}>
        <div style={{
            display: 'inline-block',
            margin: '-14px 0 0 0px',
            verticalAlign: 'top',
            height: 12,
            width: 12
        }}>
            <img src="/assets/ptv/radiowave-bus.gif" style={{ width: 12, height: 12 }} />
        </div>
    </div>
}
// const modeSort = {Trains: 1, Metro: 1, Ferries: 2, 'Light Rail': 3, Coaches: 4, Buses: 5, 'Other modes': 6}
interface DotPrecinctDeparturesViewProps {
    precinctStops: IPrecinctStop[];
    durationPerPage: number
    colsPerPage: number
}
export const DotPrecinctDeparturesView: React.FC<DotPrecinctDeparturesViewProps> = ({
    precinctStops,
    durationPerPage,
    colsPerPage
}) => {
    const { getDimension } = useAs1428(55);
    const dispatch = useDispatch();

    const columns = useMemo(() => {
        const cutoff = moment().add(12, 'h').unix();
        const now = moment().unix();
        // console.log(precinctDepartures)
        const columns: any[] = [];
        let column: any[] = [];

        // const stops = [];\

        const nextColumn = () => {
            columns.push(column)

            for (let loop = 0; loop < 5; loop++) {
                const currentHeight = column.reduce((a, s) => a + getDimension(165 + 45 + 15 + 35 + s.displayCount * 85), 0);

                // adjust as required
                const delta = 1080 - (currentHeight);
                const canAdd = Math.floor(delta / getDimension(85));
                const stopsEligible = column.filter(v => v.departures.length > v.displayCount);

                if (canAdd > 0 && stopsEligible.length > 0) {
                    const canAddEach = Math.floor(canAdd / stopsEligible.length);
                    let added = 0;
                    // console.log({canAddEach, added})
                    if (canAddEach > 0) {
                        for (const s of stopsEligible) {
                            s.displayCount += canAddEach
                        }
                    } else {
                        for (const s of stopsEligible) {
                            if (added <= canAddEach) {
                                s.displayCount++;
                                added++;
                            }
                        }
                    }
                } else {
                    break;
                }
                // console.log({currentHeight, delta, canAdd, column})
            }
            column = []
        }

        for (const stop of (precinctStops || [])) {
            let defaultMode = stop.stop.modes[0];
            if (!defaultMode && stop.departures[0]) {
                defaultMode = stop.departures[0].routeMode
            }

            const modeIcon = getModeIconFromString(defaultMode);
            const modeName = getModeNameFromString(defaultMode);

            const eligibleDepartures = stop.departures.filter(v => v.departureTime >= now && v.departureTime <= cutoff);

            if (eligibleDepartures.length === 0) { continue; }

            const displayCount = Math.max(1, Math.min(6, eligibleDepartures.length));

            const currentHeight = column.reduce((a, s) => a + getDimension(165 + 45 + 15 + 35 + s.displayCount * 85), 0);
            const stopHeight = getDimension(165 + 45 + 15 + 35 + 85 * displayCount);

            if ((currentHeight + stopHeight) > 1080) {
                nextColumn();
                column = [];
            }

            column.push({
                stop: stop.stop,
                modeIcon,
                modeName,
                defaultMode,
                departures: eligibleDepartures,
                displayCount
            })
        }

        if (column.length > 0) {
            nextColumn()
        }
        // console.log({columns})
        // console.log(arr)
        return columns;
    }, [precinctStops, getDimension]);

    const pages = columns.length > 0 ? Math.ceil(columns.length / colsPerPage) : 0;

    useEffect(() => {
        // console.log({pages, durationPerPage})
        if (pages > 0) {
            // console.log('dispatched', {pages, durationPerPage})
            dispatch(actions.setRotationProperties({
                rotationProperties: {
                    pages,
                    minDuration: durationPerPage * pages
                }
            }))
        } else {
            dispatch(actions.setRotationProperties({}))
        }
    }, [dispatch, durationPerPage, pages]);

    const [page, setPage] = useState<number>(0)

    useInterval(() => {
        if (page >= (pages - 1)) {
            setPage(0);
        } else {
            setPage(page + 1);
        }
    }, durationPerPage * 1000);

    const filteredColumns = useMemo(() => {
        return columns.filter((_col, i) => {
            if (Math.floor(i / colsPerPage) !== page) { return false }
            return true;
        })
    }, [colsPerPage, columns, page])

    const [alertPage, setAlertPage] = useState<number>(0)

    const alerts = useMemo(() => {
        const dedupedAlerts: { [k: string]: IAlert } = {};
        for (const stop of precinctStops) {
            if (!stop.alerts) { continue }
            for (const alert of stop.alerts) {
                dedupedAlerts[alert.id] = alert;
            }
        }

        return Object.values(dedupedAlerts)
    }, [precinctStops]);

    useInterval(() => {
        if (alertPage >= (alerts.length - 1)) {
            setAlertPage(0);
        } else {
            setAlertPage(alertPage + 1);
        }
    }, durationPerPage * 1000);

    const { data: liveData } = useSWR('https://vicdot-dmu-nimble.jxeeno.workers.dev/api/pid', fetcher, { refreshInterval: 1000 * 60 })


    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', background: '#344049', padding: 32 }}>
                <div style={{ flex: 1, color: '#FFFFFF', paddingRight: 16 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        {/* <img src="https://anytrip.com.au/img/vicptv/regional-train.png" style={{height: 84, width: 84}} /> */}
                        <img src="https://anytrip.com.au/img/vicptv/bus.png" style={{ height: 72, width: 72 }} />
                        <div style={{ fontSize: 48, color: '#FFFFFF', fontWeight: 400, paddingLeft: 20 }}>Buses</div>
                    </div>

                    {precinctStops.filter(v => v.stop.modes.includes('au3:buses')).slice(0, 2).map(stop => <div key={stop.stop.id}>
                        {/* <div style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-end'}}>
                            <div style={{flex: 1}}>
                                
                            </div>
                            
                        </div> */}
                        <div style={{ marginTop: 20 }}>
                            <div style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderBottomColor: '#FFFFFF', flexDirection: 'row', display: 'flex', alignItems: 'flex-end', fontSize: 24, paddingBottom: 6 }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{ fontSize: 24, marginTop: 12 }}>
                                        Departing from
                                    </div>
                                    <div style={{ fontSize: 40 }}>
                                        {stop.stop.fullName.replace(/\//g, ' / ')}
                                    </div>
                                </div>
                                <div style={{ textAlign: 'right'}}>
                                    <div style={{ color: '#FFB530', fontSize: 24, whiteSpace: 'nowrap', width: 160, flexDirection: 'row', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                        <img src="/svg/ptv-walk.svg" style={{ marginRight: 12 }} />
                                        <div>{WALK_CONFIG[stop.stop.fullName]?.time || 1} min</div>
                                        <img src="/svg/ptv-arrow-forward.svg" style={{ marginLeft: 12, transform: `rotate(${WALK_CONFIG[stop.stop.fullName]?.direction}deg)` }} />
                                    </div>
                                    <div style={{ paddingTop: 6 }}>Departing</div>
                                </div>
                            </div>
                            {
                                stop.departures.slice(0, 4).map((departure, i) => <div key={i} style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderBottomColor: '#FFFFFF', flexDirection: 'row', display: 'flex', alignItems: 'center', height: 70, fontWeight: 'bold', fontSize: 32 }}>
                                    <div style={{ width: 90 }}>
                                        {departure.routeNumber}
                                    </div>
                                    <div style={{ flex: 1, lineHeight: '32px', marginLeft: 12 }}>
                                        {departure.destination.headline}
                                    </div>
                                    {departure.occupancy ? <img src={`/svg/ptv-ridespace-${departure.occupancy}.svg`} /> : (
                                        RIDE_SPACE_ROUTES.includes(departure.routeNumber) ? <img src={`/svg/ptv-ridespace-0.svg`} /> : null
                                    )}
                                    <div style={{ width: 140, textAlign: 'right' }}>{renderDepartureTime(
                                        departure.departureTime,
                                        moment().unix(),
                                        DepartureTimeMode.DOT
                                    )}{departure.delay != null && <RealTimeWave />}</div>
                                </div>)
                            }
                        </div>
                    </div>)}
                </div>
                <div style={{ flex: 1, color: '#FFFFFF', paddingLeft: 16, paddingRight: 16 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        {/* <img src="https://anytrip.com.au/img/vicptv/regional-train.png" style={{height: 84, width: 84}} /> */}
                        <img src="https://anytrip.com.au/img/vicptv/train.png" style={{ height: 72, width: 72 }} />
                        <div style={{ fontSize: 48, color: '#FFFFFF', fontWeight: 400, paddingLeft: 20 }}>Metro</div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <div style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderBottomColor: '#FFFFFF', flexDirection: 'row', display: 'flex', alignItems: 'flex-end', fontSize: 24, paddingBottom: 6 }}>
                            <div style={{ flex: 1 }}>
                                <div>Destination</div>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <div>Departing</div>
                            </div>
                        </div>
                        {precinctStops.filter(v => v.stop.modes.includes('au3:metrotrains')).flatMap((stop, i) => stop.departures.map((departure, ii) => <div key={`${i}.${ii}`} style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderBottomColor: '#FFFFFF', flexDirection: 'row', display: 'flex', alignItems: 'center', height: 88, fontWeight: 'bold', fontSize: 32 }}>
                            <div style={{ width: 14, background: `#${departure.routeColor}`, height: 84 }}>

                            </div>
                            <div style={{ flex: 1, marginLeft: 12 }}>
                                <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                                    {moment.unix(departure.scheduledDepartureTime).format('h:mm a')}
                                </div>
                                <div style={{ lineHeight: '32px' }}>
                                    {departure.destination.headline}
                                </div>
                                <div style={{ fontSize: 22, fontWeight: 'normal' }}>
                                {departure.destination.subtitle}
                                </div>
                            </div>
                            {departure.occupancy && <img src={`/svg/ptv-ridespace-${departure.occupancy}.svg`} />}
                            <div style={{ width: 140, textAlign: 'right' }}>
                                <div style={{ color: '#FFB530', fontSize: 20 }}>
                                    Platform <span style={{ fontSize: 24 }}>{
                                    departure.destination.headline === 'Flinders Street' || departure.destination.headline === 'Southern Cross' || departure.destination.headline === 'City Loop' ? 1 : 2
                                    }</span>
                                </div>
                                <div>
                                    {renderDepartureTime(
                                        departure.departureTime,
                                        moment().unix(),
                                        DepartureTimeMode.DOT
                                    )}{departure.delay != null && <RealTimeWave />}</div>
                            </div>
                        </div>)).slice(0, 4)}

                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', paddingTop: 32 }}>
                            <img src="https://anytrip.com.au/img/vicptv/regional-train.png" style={{ height: 72, width: 72 }} />
                            <div style={{ fontSize: 48, color: '#FFFFFF', fontWeight: 400, paddingLeft: 20 }}>V/Line</div></div>
                    </div>
                    <div style={{ marginTop: 12 }}>
                        <div style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderBottomColor: '#FFFFFF', flexDirection: 'row', display: 'flex', alignItems: 'flex-end', fontSize: 24, paddingBottom: 6 }}>
                            <div style={{ flex: 1 }}>
                                <div>Destination</div>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <div>Departing</div>
                            </div>
                        </div>
                        {precinctStops.filter(v => v.stop.modes.includes('au3:vlinetrains')).flatMap((stop, i) => stop.departures.map((departure, ii) => <div key={`${i}.${ii}`} style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderBottomColor: '#FFFFFF', flexDirection: 'row', display: 'flex', alignItems: 'center', height: 70, fontWeight: 'bold', fontSize: 32 }}>
                            <div style={{ width: 14, background: `#${departure.routeColor}`, height: 66 }}>

                            </div>
                            <div style={{ flex: 1, marginLeft: 12 }}>
                                <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                                    {moment.unix(departure.scheduledDepartureTime).format('h:mm a')}
                                </div>
                                <div style={{ lineHeight: '32px' }}>
                                    {departure.destination.headline}
                                </div>
                            </div>
                            {/* <img src="/svg/ptv-ridespace-1.svg" /> */}
                            <div style={{ width: 140, textAlign: 'right' }}>
                                <div style={{ color: '#FFB530', fontSize: 20 }}>
                                    Platform <span style={{ fontSize: 24 }}>4</span>
                                </div>
                                <div>
                                    {renderDepartureTime(
                                        departure.departureTime,
                                        moment().unix(),
                                        DepartureTimeMode.DOT
                                    )}{departure.delay != null && <RealTimeWave />}</div>
                            </div>
                        </div>)).slice(0, 4)}
                    </div>
                </div>
                <div style={{ flex: 1, color: '#FFFFFF', paddingLeft: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 18 }}>
                        <img src="/assets/ptv/mask.png" />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 18, marginBottom: 18, width: 510 }}>
                        <div style={{ height: 50, background: 'black', color: 'white', fontSize: 32, fontWeight: 'bold', textAlign: 'center' }}>
                            Other travel options from here
                        </div>
                        <div style={{ height: 215, background: '#A9B2B8', color: '#000000', fontSize: 36, fontWeight: 'bold', justifyContent: 'space-around' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 14 }}>
                                <img style={{ marginRight: 10, transform: 'rotate(315deg)' }} src="/svg/ptv-arrow-forward-black.svg" />
                                <img style={{ marginRight: 10 }} src="/svg/ptv-taxi.svg" />
                                <div style={{ flex: 1, textAlign: 'left' }}>Taxi</div>
                                <div style={{ color: 'white' }}>{liveData?.waitTimes?.taxi || '1'} min wait</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 14 }}>
                                <img style={{ marginRight: 10 }} src="/svg/ptv-arrow-forward-black.svg" />
                                <img style={{ marginRight: 10 }} src="/svg/ptv-rideshare.svg" />
                                <div style={{ flex: 1, textAlign: 'left' }}>Rideshare</div>
                                <div style={{ color: 'white' }}>{liveData?.waitTimes?.rideShare || '3'} min wait</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 14 }}>
                                <img style={{ marginRight: 10 }} src="/svg/ptv-arrow-forward-black.svg" />
                                <img style={{ marginRight: 10 }} src="/svg/ptv-cycle.svg" />
                                <div style={{ flex: 1, textAlign: 'left' }}>e-bikes</div>
                                <div style={{ color: 'white' }}>{liveData?.waitTimes?.ride || '6'} min walk</div>
                            </div>
                        </div>
                    </div>

                    {
                        alerts && alerts.length > 0 && alerts[alertPage] &&
                        <div style={{ background: '#E8E5E1', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', marginTop: 18, width: 510 }}>
                            <div style={{ height: 50, paddingLeft: 22, paddingRight: 22, background: 'black', color: '#EB5D2A', fontSize: 32, fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ width: 58, display: 'inline-block' }}>
                                    <div style={{
                                        display: 'inline-block',
                                        margin: '14px 0 0 0px',
                                        verticalAlign: 'top',
                                        height: 49,
                                        width: 49
                                    }}>
                                        <img src="/svg/ptv-disruptions.svg" style={{ zIndex: 99, position: 'absolute' }} />
                                    </div>
                                </div>
                                Disruptions
                            </div>

                            <DotDisruption key={alerts[alertPage].id} alert={alerts[alertPage]} />

                            {/* <div style={{ padding: 22, color: 'black', fontSize: 24, flex: 1, zIndex: 99 }}>
                                <div style={{ overflow: 'hidden', maxHeight: 216 }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: 12 }}>
                                        {alerts[alertPage].header}
                                    </div>

                                    <div>
                                        {alerts[alertPage].description}
                                    </div>
                                </div>
                            </div> */}

                            <div>
                                <img src="/assets/ptv/veh-base.png" style={{ marginTop: -50, marginBottom: -56 }} />
                            </div>
                        </div>
                    }

                </div>
                {/* {filteredColumns.map((column, i, arr) => {
                    return <div key={i} style={{flex: 1, flexShrink: 0, borderLeft: i === 0 ? undefined : '1px solid #344049'}}>
                        {
                            column.map((stop: any, si: number) => <div key={stop.stop.id}>
                                <PlrModeHeader currentTime={arr.length-1 === i && si === 0} modeName={stop.modeName} modeIcon={stop.modeIcon} modeLocation={`from ${stop.stop.fullName}`} />
                                <PlrDepartureItems departures={stop.departures.slice(0, stop.displayCount)} />
                            </div>)
                        }
                    </div>
                })}
                {Array(colsPerPage - filteredColumns.length).fill(1).map((_, i) => <div style={{flex: 1, flexShrink: 0}} key={i} />)} */}
            </div>
            <div style={{ background: '#FFB930', height: 76, flexShrink: 0, paddingLeft: 32, paddingRight: 32, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ fontSize: 28, lineHeight: '26px' }}>
                        Network<br />Status
                    </div>
                    {liveData?.networkStatus.map((status: any, i: number) => {
                        return <React.Fragment key={i}>
                            <div style={{ paddingLeft: 32, fontSize: 36, fontWeight: 'bold' }}>
                                {status.name}
                            </div>
                            <div style={{ borderRadius: 20, height: 40, background: 'white', marginLeft: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ height: 32, width: 32, borderRadius: 16, background: status.color, marginLeft: 4, marginRight: 12 }}>

                                </div>
                                <div style={{ paddingRight: 12, fontSize: 26 }}>
                                    {status.text}
                                </div>
                            </div>
                        </React.Fragment>
                    })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ textAlign: 'right', paddingRight: 24, fontSize: 24 }}>
                        <div>Chance of rain</div>
                        <div>{liveData?.chanceOfRain || '0'}%</div>
                    </div>
                    <div style={{ textAlign: 'right', paddingRight: 24, fontSize: 24 }}>
                        <div>Feels like</div>
                        <div>{liveData?.tempFeelsLike || '16'}&deg;C</div>
                    </div>
                    <div style={{ textAlign: 'right', paddingRight: 24, fontSize: 24 }}>
                        <div>Currently</div>
                        <div>{liveData?.temp || '20'}&deg;C</div>
                    </div>
                    <div style={{ paddingLeft: 32, fontSize: 48, fontWeight: 'bold', fontVariantNumeric: 'tabular-nums' }}>
                        <Moment format="h:mm:ss a" interval={1000} />
                    </div>

                </div>
            </div>
        </div>
    )
    // return (
    //     // <div style={{ flex: 1, display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'none' }}>
    //     <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
    //         <div style={{ flex: 1, flexGrow: 1, flexShrink: 0, minWidth: 0 }}>
    //             {groups.map((group: any) => {
    //                 return <div key={group.stop.id}>
    //                     <PlrModeHeader modeName={group.defaultMode} modeIcon={group.modeIcon} modeLocation={`from ${group.stop.fullName}`} />
    //                     <PlrDepartureItems />
    //                 </div>
    //             })}
    //         </div>
    //         <div style={{ flex: 1, flexGrow: 1, flexShrink: 0, minWidth: 0 }}>
    //             {/* <PlrModeHeader modeName="Trains" modeIcon="au2:sydneytrains" modeLocation="from Platform 1" />
    //             <PlrModeHeader modeName="Trains" modeIcon="au2:sydneytrains" modeLocation="from Platform 1" />
    //             <PlrModeHeader modeName="Trains" modeIcon="au2:sydneytrains" modeLocation="from Platform 1" /> */}
    //         </div>
    //     </div>
    // );
};
