import React, { useState, useEffect } from 'react';

interface ScaledWrapperProps {
    minRatio?: number;
    maxRatio?: number;
    // ratio: number;
    children: React.ReactNode;
    width: number;
    height: number;
}

export const ScaledWrapper: React.FC<ScaledWrapperProps> = ({minRatio, maxRatio, width, height, children}) => {
    const ratio = width/height;

    // const internalMinRatio = minRatio || ratio * 0.3;
    // const internalMaxRatio = maxRatio || ratio * 1.6;

    const calcScaleFactor = () => {
        const windowRatio = innerWidth/innerHeight;

        if(ratio === windowRatio){
            return (innerWidth/width)
        }

        const widthScaleFactor = innerWidth/width;
        const heightScaleFactor = innerHeight/height;

        return (Math.min(widthScaleFactor, heightScaleFactor));
        
    }

    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [scaleFactor, setScaleFactor] = useState(calcScaleFactor());

    const reportWindowSize = () => {
        setInnerHeight(window.innerHeight);
        setInnerWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', reportWindowSize);
        return () => {
            window.removeEventListener('resize', reportWindowSize);
        }
    });

    useEffect(() => {
        setScaleFactor(calcScaleFactor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerHeight, innerWidth])

    return (scaleFactor != null ? <div className="scaled-wrapper" style={{
        height,
        width,
        transform: `scale(${scaleFactor})`,
        left: (innerWidth-(width*scaleFactor))/2,
        top: (innerHeight-(height*scaleFactor))/2,
    }}>
        {children}
    </div> : null)
}

export default ScaledWrapper;
