import { createAction, handleActions } from 'redux-actions';

const types = {
    SET_TTS_URL: 'SET_TTS_URL',
    SET_MAX_FREQUENCY: 'SET_MAX_FREQUENCY',
    SET_WAIT_BEFORE_REPEATING: 'SET_WAIT_BEFORE_REPEATING',
    RESET_TTS_URL: 'RESET_TTS_URL',
    TTS_FINISHED: 'TTS_FINISHED'
}
export interface TtsState {
  ttsUrl?: string;
  audioMaxFrequency?: number;
  audioWaitBeforeRepeating?: number;
}

const initialState: TtsState = {}

export const reducer = handleActions<TtsState>(
  {
    [types.SET_TTS_URL]: (state, action) => {
      const { ttsUrl } = action.payload;
      return {
        ...state,
        ttsUrl
      };
    },
    [types.SET_MAX_FREQUENCY]: (state, action) => {
      const { audioMaxFrequency } = action.payload;
      return {
        ...state,
        audioMaxFrequency
      };
    },
    [types.SET_WAIT_BEFORE_REPEATING]: (state, action) => {
      const { audioWaitBeforeRepeating } = action.payload;
      return {
        ...state,
        audioWaitBeforeRepeating
      };
    },
    [types.RESET_TTS_URL]: (state) => {
      return {
        ...state,
        ttsUrl: undefined
      };
    }
  },
  initialState
);

export const actions = {
  setTtsUrl: createAction<TtsState>(types.SET_TTS_URL),
  setMaxFrequency: createAction<TtsState>(types.SET_MAX_FREQUENCY),
  setWaitBeforeRepeating: createAction<TtsState>(types.SET_WAIT_BEFORE_REPEATING),
  resetTtsUrl: createAction(types.RESET_TTS_URL),
  ttsFinished: createAction(types.TTS_FINISHED)
};

export const tts = {
  actions,
  types,
  reducer,
};
