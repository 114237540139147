import moment from "moment";
import React from 'react';

export const abbreviateStationName = (stopName: string, opts?: any) => {
    let name = stopName;
    name = name.replace(/ Station$/, "");
    name = name.replace(/ Coach Stop$/, "");
    name = name.replace(/ Wharf( [0-9])?$/, "");

    const pidAbbreviations: {[k: string]: string} = {
      'Bondi Junction': 'Bondi Jn',
      'Domestic Airport': 'Domestic ✈',
      'International Airport': 'International ✈',
      'Macquarie Fields': 'Macquarie Flds',
      'Macquarie University': 'Macquarie Uni',
      'Mount Colah': 'Mt Colah',
      'Mount Druitt': 'Mt Druitt',
      'Mount Kuring-gai': 'Mt Kuring-gai',
      'North Strathfield': 'N Strathfield',
      'Hills Showground': 'Hills Showgrd',
      'Mount Victoria': 'Mt Victoria',

      'Hawkesbury River': 'Hawkesbury Rvr',
      'North Wollongong': 'N Wollongong',
      'Newcastle Interchange': 'Newcastle Intg',
      'Shellharbour Junction': 'Shellhbr Jn',
      'Shellharbour': 'Shellhbr Jn',
      'Port Kembla North': 'Port Kembla N',
      'Victoria Street': 'Victoria St',

      'via Airport stations': 'via Airport stations ✈',

      'Capital Square': 'Capital Sq',
      'Exhibition Centre': 'Exhibition',
      'Convention Centre': 'Convention',
      'John Street Square': 'John Street Sq',

      'Allworth Turnoff': 'Allworth tf',
      'Billinudgel Turnoff': 'Billinudgel tf',
      'Carrathool Turnoff': 'Carrathool tf',
      'Mount George Turnoff': 'Mt George tf',
      'Mt George Turnoff': 'Mt George tf',
      'Mount Russell Turnoff': 'Mt Russell tf',
      'Mt Russell Turnoff': 'Mt Russell tf',

      'Brisbane (Roma Street)': 'Brisbane',
      'Melbourne (Southern Cross)': 'Melbourne',

      'Broken Hill Town': 'Broken Hill Tn',
      'Condobolin Town': 'Condobolin Tn',
      'Gloucester Town': 'Gloucester Tn',
      'Mooree Town': 'Mooree Tn',
      // 'Wingham Town': 'Wingham Tn',

      'Brunswick Heads': 'Brunswick Hds',
      'Burleigh Heads': 'Burleigh Hds',
      'Lennox Head': 'Lennox Hd',
      'South Tweed Heads': 'Sth Tweed Hds',
      'Tweed Heads': 'Tweed Hds',

      'Limeburners Creek': 'Limeburners Ck',

      'Raymond Terrace': 'Raymond Tce',
    };

    return pidAbbreviations[name] || name;
  }

export const relativeTime = (ts: number) => {
    const timeToGo = ts - moment().unix();
    if(timeToGo < 60){
        return <span></span>;
    }else if(timeToGo < 60*60){
        return <span>{Math.floor(timeToGo/60)} <span className="min">min</span></span>;
    }else if(timeToGo < 24*60*60){
        return moment.unix(ts).format('HH:mm');
    }
}