import { all } from 'redux-saga/effects';
import { idi } from './idi.saga';
import { idiPolling } from './idi.polling.saga';
import { idiTts } from './idi.tts.saga';

export const sagas = {
  idi,
  idiPolling,
  idiTts
};

export function* rootSaga() {
  yield all([
    idi(),
    idiPolling(),
    idiTts()
  ]);
}
