export const SHORT_PLATFORM_INTERCHANGE_MODES: {[k: string]: any} = {
    "au2:233610": {
        "modes": [
            "Train"
        ]
    },
    "au2:228920": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:252710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:26401": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:222020": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:23501": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:220520": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:206410": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:213110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:207730": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:214410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:251540": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:228310": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:221610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:220010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:220710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:257420": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:279538": {
        "modes": [
            "Train",
            "Regional coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:211910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:278620": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:251810": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:23971": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:219210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214120": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:232230": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:208110": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:253510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:220910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:220720": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:214320": {
        "modes": [
            "Train"
        ]
    },
    "au2:278510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214810": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:277410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:27991": {
        "modes": [
            "Train"
        ]
    },
    "au2:23821": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:254110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:253320": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:202210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:228410": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:257610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi",
            "Regional coach"
        ]
    },
    "au2:233510": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:40002": {
        "modes": [
            "Train",
            "Regional coach"
        ]
    },
    "au2:229210": {
        "modes": [
            "Train",
            "Regional coach",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars "
    },
    "au2:28801": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:278410": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:251610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257810": {
        "modes": [
            "Train",
            "Bus",
            "Regional coach"
        ]
    },
    "au2:26211": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:257620": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:213410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:216620": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214210": {
        "modes": [
            "Train"
        ]
    },
    "au2:256020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:219410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:26041": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:216610": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:219320": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:228520": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:222910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:211810": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:221810": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:216330": {
        "modes": [
            "Train"
        ]
    },
    "au2:24701": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:217030": {
        "modes": [
            "Train"
        ]
    },
    "au2:200060": {
        "modes": [
            "Train",
            "Bus",
            "Light rail",
            "Taxi"
        ]
    },
    "au2:206710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:211920": {
        "modes": [
            "Train"
        ]
    },
    "au2:216220": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:200020": {
        "modes": [
            "Train",
            "Bus",
            "Ferry",
            "Taxi"
        ]
    },
    "au2:275630": {
        "modes": [
            "Train"
        ]
    },
    "au2:214230": {
        "modes": [
            "Train"
        ]
    },
    "au2:250850": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:228430": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:24501": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:251530": {
        "modes": [
            "Train",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:222610": {
        "modes": [
            "Train"
        ]
    },
    "au2:213810": {
        "modes": [
            "Train"
        ]
    },
    "au2:28772": {
        "modes": [
            "Train"
        ]
    },
    "au2:250030": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:27011": {
        "modes": [
            "Train"
        ]
    },
    "au2:25901": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:251820": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:208120": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:250520": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:223020": {
        "modes": [
            "Train",
            "Bus",
            "Ferry",
            "Taxi"
        ]
    },
    "au2:213210": {
        "modes": [
            "Train"
        ]
    },
    "au2:26601": {
        "modes": [
            "Train",
            "Coach"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:253020": {
        "modes": [
            "Train",
            "Bus",
            "Coach",
            "Taxi"
        ]
    },
    "au2:28781": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:211410": {
        "modes": [
            "Train"
        ]
    },
    "au2:202020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:276710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:226420": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:256910": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:28301": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:220363": {
        "modes": [
            "Train",
            "Light rail"
        ]
    },
    "au2:220310": {
        "modes": [
            "Train",
            "Bus",
            "Light rail"
        ]
    },
    "au2:211720": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:242010": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:221320": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:232330": {
        "modes": [
            "Train"
        ]
    },
    "au2:275310": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:212210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:202710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:217426": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:275020": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:223310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:212110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:204310": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:28771": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:24411": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:257930": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:216510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:251910": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:228330": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:277610": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:214020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:253420": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:28311": {
        "modes": [
            "Train",
            "Coach",
            "Bus"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:277310": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:216710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:24221": {
        "modes": [
            "Train"
        ]
    },
    "au2:207210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:225040": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:258010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:24601": {
        "modes": [
            "Train",
            "Regional coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214240": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:201710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:233410": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:26801": {
        "modes": [
            "Train"
        ]
    },
    "au2:216110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:23801": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:25811": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:222710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:230310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:25871": {
        "modes": [
            "Train"
        ]
    },
    "au2:215010": {
        "modes": [
            "Train"
        ]
    },
    "au2:208310": {
        "modes": [
            "Train",
            "Bus",
            "Ferry"
        ]
    },
    "au2:277910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:223320": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:250810": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:26581": {
        "modes": [
            "Train",
            "Coach"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:232210": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:232010": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:242040": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:217310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:207720": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:219310": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:222010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:256510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:202030": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:28782": {
        "modes": [
            "Train"
        ]
    },
    "au2:222620": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:26631": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:278020": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:253010": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:24401": {
        "modes": [
            "Train",
            "Bus",
            "Coach",
            "Taxi"
        ]
    },
    "au2:24391": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:253330": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:207110": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:201110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:220810": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:274720": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:223230": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:221710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:225620": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last car"
    },
    "au2:23521": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:228910": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:24741": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:219510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:275030": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:278310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:27051": {
        "modes": [
            "Train"
        ]
    },
    "au2:216310": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:217933": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:256010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:278010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars "
    },
    "au2:204920": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:214110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:277820": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:207010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:225070": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:279010": {
        "modes": [
            "Train",
            "Coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:217020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:232110": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:223220": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:250510": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:256030": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:201520": {
        "modes": [
            "Train"
        ]
    },
    "au2:24471": {
        "modes": [
            "Train",
            "Coach",
            "Taxi"
        ]
    },
    "au2:256410": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:211340": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:211310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:232020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214820": {
        "modes": [
            "Train"
        ]
    },
    "au2:220410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:200030": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:242050": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:257940": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:202010": {
        "modes": [
            "Train",
            "Taxi"
        ]
    },
    "au2:211430": {
        "modes": [
            "Train",
            "Bus",
            "Ferry",
            "Taxi"
        ]
    },
    "au2:21141": {
        "modes": [
            "Train",
            "Ferry"
        ]
    },
    "au2:278030": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:256310": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:256810": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:28791": {
        "modes": [
            "Train"
        ]
    },
    "au2:216010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:232310": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:279821": {
        "modes": [
            "Regional train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:206110": {
        "modes": [
            "Train",
            "Bus",
            "Ferry",
            "Taxi"
        ]
    },
    "au2:20611": {
        "modes": [
            "Train",
            "Bus",
            "Ferry",
            "Taxi"
        ]
    },
    "au2:232050": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:253310": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:256610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:222810": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257510": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:24001": {
        "modes": [
            "Train",
            "Coach",
            "Taxi"
        ]
    },
    "au2:226410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:222310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257710": {
        "modes": [
            "Train",
            "Bus",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:207910": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:277010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:208010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:278610": {
        "modes": [
            "Train",
            "Regional coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:275610": {
        "modes": [
            "Train"
        ]
    },
    "au2:23381": {
        "modes": [
            "Train"
        ]
    },
    "au2:200040": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:233310": {
        "modes": [
            "Train",
            "Bus",
            "Coach"
        ]
    },
    "au2:24481": {
        "modes": [
            "Train",
            "Coach",
            "Taxi"
        ]
    },
    "au2:2250772": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:23901": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:27001": {
        "modes": [
            "Train",
            "Coach"
        ]
    },
    "au2:220920": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:229310": {
        "modes": [
            "Train",
            "Bus",
            "Light rail",
            "Taxi"
        ]
    },
    "au2:204210": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:225060": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:207620": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:211320": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:213710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:206010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:250010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:252920": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:222320": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:212710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:28001": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:250830": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:225810": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:221110": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:221310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:28701": {
        "modes": [
            "Train",
            "Regional coach"
        ]
    },
    "au2:215020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:242110": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:214530": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:212020": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:275010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257920": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:222210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:204910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257110": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:225030": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:250530": {
        "modes": [
            "Train"
        ]
    },
    "au2:250540": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:219610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:207310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:276310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:26201": {
        "modes": [
            "Train",
            "Coach"
        ]
    },
    "au2:23431": {
        "modes": [
            "Train",
            "Coach",
            "Taxi"
        ]
    },
    "au2:200064": {
        "modes": [
            "Train",
            "Bus",
            "Light rail",
            "Taxi"
        ]
    },
    "au2:201510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the first 6 cars"
    },
    "au2:214310": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:221210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:213820": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:275320": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:276510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:221010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:221620": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:276610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214220": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:206910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:2790128": {
        "modes": [
            "Train"
        ]
    },
    "au2:211610": {
        "modes": [
            "Train"
        ]
    },
    "au2:230430": {
        "modes": [
            "Train"
        ]
    },
    "au2:24521": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:251510": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:276220": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:233710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:216210": {
        "modes": [
            "Train"
        ]
    },
    "au2:214710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:2529218": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:233010": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:277720": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:200050": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:206520": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:276010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:204410": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:204810": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:250840": {
        "modes": [
            "Train"
        ]
    },
    "au2:213510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:28201": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:213010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:223210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:204420": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257310": {
        "modes": [
            "Train",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:257950": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:234012": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:258011": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:27871": {
        "modes": [
            "Train"
        ]
    },
    "au2:24301": {
        "modes": [
            "Train",
            "Coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:232220": {
        "modes": [
            "Train"
        ]
    },
    "au2:225020": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last car's rear door"
    },
    "au2:232030": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:211710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:204430": {
        "modes": [
            "Train"
        ]
    },
    "au2:228420": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:26551": {
        "modes": [
            "Train",
            "Coach"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:251550": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:212010": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:232240": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:214610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:200070": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:251830": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:225910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:207410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:220530": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:252610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:235813": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:24551": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:277710": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:232320": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:216320": {
        "modes": [
            "Train"
        ]
    },
    "au2:276520": {
        "modes": [
            "Train"
        ]
    },
    "au2:26501": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ]
    },
    "au2:207610": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:207710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:23541": {
        "modes": [
            "Train",
            "Coach"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:242020": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:230420": {
        "modes": [
            "Train"
        ]
    },
    "au2:230410": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:225930": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:207420": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:277420": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:217010": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:223330": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:24461": {
        "modes": [
            "Train",
            "Coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:206020": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:28202": {
        "modes": [
            "Train",
            "Coach"
        ]
    },
    "au2:278210": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars "
    },
    "au2:214520": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:274710": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:23411": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:211420": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:214510": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:219520": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:23391": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:275620": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:257910": {
        "modes": [
            "Train"
        ]
    },
    "au2:24291": {
        "modes": [
            "Train"
        ]
    },
    "au2:242030": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the middle doors"
    },
    "au2:220510": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:250020": {
        "modes": [
            "Train",
            "Coach",
            "Bus",
            "Taxi"
        ]
    },
    "au2:206510": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:251520": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:225010": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last car's rear door"
    },
    "au2:277810": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 6 cars"
    },
    "au2:223010": {
        "modes": [
            "Train",
            "Bus"
        ]
    },
    "au2:251710": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:225610": {
        "modes": [
            "Train",
            "Bus",
            "Ferry",
            "Taxi"
        ]
    },
    "au2:225940": {
        "modes": [
            "Train",
            "Bus"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 4 cars"
    },
    "au2:200080": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:225920": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:219910": {
        "modes": [
            "Train",
            "Bus",
            "Taxi"
        ]
    },
    "au2:25821": {
        "modes": [
            "Train",
            "Regional coach",
            "Taxi"
        ],
        "shortPlatform": "Short platforms, please see the train guard"
    },
    "au2:216120": {
        "modes": [
            "Train"
        ]
    },
    "au2:257540": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last 2 cars"
    },
    "au2:278630": {
        "modes": [
            "Train"
        ],
        "shortPlatform": "Short platforms, leave the train from the last car's rear door"
    }
};