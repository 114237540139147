import React, { useState, useEffect } from 'react';

export const FontSizeJig : React.FC = () => {
    const [fontSize, setFontSize] = useState(94);
    const [xHeight, setXHeight] = useState(70);

    useEffect(() => {
        setFontSize(Math.round(xHeight * 1.343))
    }, [xHeight]);

    return <div style={{flex: 1, background: 'white', height: '100%', padding: 40}}>
        <div style={{height: 600}}>
            <div style={{
                marginBottom: 50,
                borderColor: 'black',
                borderWidth: 1,
                fontSize,
                lineHeight: `${xHeight}px`,
                height: xHeight,
                fontFamily: 'frank-new, sans-serif',
                borderStyle: 'solid'
            }}>
                X Reg FS: {Math.round(fontSize)}, XH: {Math.round(xHeight)} X
            </div>
            <div style={{
                marginBottom: 50,
                borderColor: 'black',
                borderWidth: 1,
                fontSize,
                lineHeight: `${xHeight}px`,
                height: xHeight,
                fontFamily: 'frank-new, sans-serif',
                fontWeight: 500,
                borderStyle: 'solid'
            }}>
                X Med FS: {Math.round(fontSize)}, XH: {Math.round(xHeight)} X
            </div>
            <div style={{
                marginBottom: 50,
                borderColor: 'black',
                borderWidth: 1,
                fontSize,
                lineHeight: `${xHeight}px`,
                height: xHeight,
                fontFamily: 'frank-new, sans-serif',
                fontWeight: 600,
                borderStyle: 'solid'
            }}>
                X Bld FS: {Math.round(fontSize)}, XH: {Math.round(xHeight)} X
            </div>
        </div>

        <input type="number" value={xHeight} onChange={(e) => {
            setXHeight(parseInt(e.target.value))
        }} />
    </div>
}