import React from 'react';
import { SpiLabel } from './SpiLabel';
import { SpiService } from '../SpiIndicator';
import moment from 'moment';
import { renderDepartureTime } from '../common/utils';

interface SpiDepartureRightProps {
    service: SpiService;
    hideLabels?: boolean;
    hidePlatform?: boolean;
}

export const SpiDepartureRight: React.FC<SpiDepartureRightProps> = ({service, hideLabels, hidePlatform}) => {
    return <div className="spi-departure-right">
        <div className="spi-departure-right-wrapper">
            {!hideLabels && <div>
                {service.carriages && service.carriages !== '?' && <SpiLabel text={`${service.carriages} cars`} />}
                {service.isBookingRequired ? <SpiLabel text="Booked seats only" style={{background: '#E65010', width: 400}} /> : <SpiLabel text={service.serviceType} />}
            </div>}

            {!hidePlatform && <div className="spi-departure-platform">
                <div className="spi-departure-platform-label">{service.platformType}</div>
                <div className={service.platformNumber && service.platformNumber.length < 3 ? `spi-departure-platform-number` : `spi-departure-platform-stop-code`}>{service.platformNumber}</div>
            </div>}

            {hidePlatform && hideLabels ? <div /> : null}

            <div className="spi-departure-countdown">
                <div className="spi-departure-countdown-label">Departs</div>
                <div className="spi-departure-countdown-time">{renderDepartureTime(service.expectedDepartureTime, moment().unix())}</div>
            </div>
        </div>
    </div>
}