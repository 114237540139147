import React from 'react';

interface Props {
    size: number;
    text: string;
    color: string;
}

export const SquareDepartureBadge : React.FC<Props> = ({text, color, size}) => {
    // const {getDimension} = useAs1428(55);
    const fontSize = text.length > 2 ? 40 : 60;
    const lineHeight = text.length > 2 ? '54px' : '45px';
    return <div style={{width: size, height: size}}>
        <div style={{position: 'absolute', width: 100, height: 100, flexShrink: 0, transformOrigin: 'top left', transform: `scale(${size/100})`, borderRadius: 16, display: 'flex', alignItems: 'center', justifyContent: 'center', background:'#FFFFFF'}}>
            <div style={{width: 84, height: 84, flexShrink: 0, background: `#${color}`, color: '#FFF', borderRadius: 8, display: 'flex', alignItems: 'center', justifyContent: 'center', lineHeight, fontSize, fontWeight: 500}}>
                {text}
            </div>
        </div>
    </div>
}