import React, { useEffect, useMemo, useState } from "react";
import { IPrecinctStop } from "../../../reducers/anytrip-api.types";
import { PlrModeHeader } from "../components/PlrModeHeader";
import { PlrDepartureItems } from "../components/PlrDepartureItems";
import { getModeIconFromString, getModeNameFromString } from "../../../utils";
import { useAs1428 } from "../hooks/useAs1428";
import moment from "moment";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers";
import { useInterval } from "../hooks/useInterval";

// const modeSort = {Trains: 1, Metro: 1, Ferries: 2, 'Light Rail': 3, Coaches: 4, Buses: 5, 'Other modes': 6}
interface SpiPrecinctDeparturesViewProps {
    precinctStops: IPrecinctStop[];
    durationPerPage: number
    colsPerPage: number
}
export const SpiPrecinctDeparturesViewV2: React.FC<SpiPrecinctDeparturesViewProps> = ({
    precinctStops,
    durationPerPage,
    colsPerPage
}) => {
    const {getDimension} = useAs1428(55);
    const dispatch = useDispatch();

    const columns = useMemo(() => {
        const cutoff = moment().add(12, 'h').unix();
        const now = moment().unix();
        // console.log(precinctDepartures)
        const columns: any[] = [];
        let column: any[] = [];

        // const stops = [];\

        const nextColumn = () => {
            columns.push(column)

            for(let loop = 0; loop < 5; loop++){
                const currentHeight = column.reduce((a, s) => a + getDimension(165 + 45 + 15 + 35 + s.displayCount * 85), 0);
                
                // adjust as required
                const delta = 1080 - (currentHeight);
                const canAdd = Math.floor(delta/getDimension(85));
                const stopsEligible = column.filter(v => v.departures.length > v.displayCount);

                if(canAdd > 0 && stopsEligible.length > 0){
                    const canAddEach = Math.floor(canAdd/stopsEligible.length);
                    let added = 0;
                    // console.log({canAddEach, added})
                    if(canAddEach > 0){
                        for(const s of stopsEligible){
                            s.displayCount += canAddEach
                        }
                    }else{
                        for(const s of stopsEligible){
                            if(added <= canAddEach){
                                s.displayCount++;
                                added++;
                            }
                        }
                    }
                }else{
                    break;
                }
                // console.log({currentHeight, delta, canAdd, column})
            }
            column = []
        }
        
        for(const stop of (precinctStops || [])){
            let defaultMode = stop.stop.modes[0];
            if(!defaultMode && stop.departures[0]){
                defaultMode = stop.departures[0].routeMode
            }

            const modeIcon = getModeIconFromString(defaultMode);
            const modeName = getModeNameFromString(defaultMode);

            const eligibleDepartures = stop.departures.filter(v => v.departureTime >= now && v.departureTime <= cutoff);

            if(eligibleDepartures.length === 0){continue;}

            const displayCount = Math.max(1, Math.min(6, eligibleDepartures.length));

            const currentHeight = column.reduce((a, s) => a + getDimension(165 + 45 + 15 + 35 + s.displayCount * 85), 0);
            const stopHeight = getDimension(165 + 45 + 15 + 35 + 85*displayCount);

            if((currentHeight + stopHeight) > 1080){
                nextColumn();
                column = [];
            }

            column.push({
                stop: stop.stop,
                modeIcon,
                modeName,
                defaultMode,
                departures: eligibleDepartures,
                displayCount
            })
        }

        if(column.length > 0){
            nextColumn()
        }
        // console.log({columns})
        // console.log(arr)
        return columns;
    }, [precinctStops, getDimension]);

    const pages = columns.length > 0 ? Math.ceil(columns.length/colsPerPage) : 0;

    useEffect(() => {
        // console.log({pages, durationPerPage})
        if(pages > 0){
            // console.log('dispatched', {pages, durationPerPage})
            dispatch(actions.setRotationProperties({
                rotationProperties: {
                    pages,
                    minDuration: durationPerPage * pages
                }
            }))
        }else{
            dispatch(actions.setRotationProperties({}))
        }
    }, [dispatch, durationPerPage, pages]);

    const [page, setPage] = useState<number>(0)

    useInterval(() => {
        if(page >= (pages-1)){
            setPage(0);
        }else{
            setPage(page+1);
        }
    }, durationPerPage * 1000);

    const filteredColumns = useMemo(() => {
        return columns.filter((_col, i) => {
            if(Math.floor(i/colsPerPage) !== page){return false}
            return true;
        })
    }, [colsPerPage, columns, page])

    return (
        <div style={{ flex: 1, display: 'flex' }}>
            {filteredColumns.map((column, i, arr) => {
                return <div key={i} style={{flex: 1, flexShrink: 0, borderLeft: i === 0 ? undefined : '1px solid #344049'}}>
                    {
                        column.map((stop: any, si: number) => <div key={stop.stop.id}>
                            <PlrModeHeader currentTime={arr.length-1 === i && si === 0} modeName={stop.modeName} modeIcon={stop.modeIcon} modeLocation={`from ${stop.stop.fullName}`} />
                            <PlrDepartureItems departures={stop.departures.slice(0, stop.displayCount)} />
                        </div>)
                    }
                </div>
            })}
            {Array(colsPerPage - filteredColumns.length).fill(1).map((_, i) => <div style={{flex: 1, flexShrink: 0}} key={i} />)}
        </div>
    )
    // return (
    //     // <div style={{ flex: 1, display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'none' }}>
    //     <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
    //         <div style={{ flex: 1, flexGrow: 1, flexShrink: 0, minWidth: 0 }}>
    //             {groups.map((group: any) => {
    //                 return <div key={group.stop.id}>
    //                     <PlrModeHeader modeName={group.defaultMode} modeIcon={group.modeIcon} modeLocation={`from ${group.stop.fullName}`} />
    //                     <PlrDepartureItems />
    //                 </div>
    //             })}
    //         </div>
    //         <div style={{ flex: 1, flexGrow: 1, flexShrink: 0, minWidth: 0 }}>
    //             {/* <PlrModeHeader modeName="Trains" modeIcon="au2:sydneytrains" modeLocation="from Platform 1" />
    //             <PlrModeHeader modeName="Trains" modeIcon="au2:sydneytrains" modeLocation="from Platform 1" />
    //             <PlrModeHeader modeName="Trains" modeIcon="au2:sydneytrains" modeLocation="from Platform 1" /> */}
    //         </div>
    //     </div>
    // );
};
