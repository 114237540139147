import React from 'react';

interface SpiBannerProps {
    children: React.ReactNode;
}

export const SpiBanner: React.FC<SpiBannerProps> = ({children}) => {
    return <div className="spi-banner">
        <div className="spi-banner-wrapper">
            <div className="spi-banner-text">
                {children}
            </div>
        </div>
    </div>
}