import React, { useEffect, useRef, useState } from 'react';
import { useAs1428 } from '../hooks/useAs1428';
import { SpiService, SpiStoppingAt } from '../SpiIndicator';
// import { abbreviateStationName } from '../../../utils';

interface SpiScrollingStoppingPatternProps {
    service: SpiService
    alternativeServices?: boolean
    dynamic?: boolean
}

const renderStop = (stoppingAt: SpiStoppingAt) => {
    return <>
        <span>{stoppingAt.displayName.replace(' ✈', '')}</span>
        {stoppingAt.airport || stoppingAt.displayName.endsWith('✈') ? <img alt="Airport" src="/svg/airport.svg" className="airport-icon" /> : ''}
        {stoppingAt.time && stoppingAt.time > 60 ? <div className="spi-stopping-pattern-walk"><img alt="Walk" src="/svg/walk.svg" /><div>{Math.round(stoppingAt.time/60)}<small> min</small></div></div> : null}
        {stoppingAt.cars ? <div className="spi-stopping-pattern-cars"><span>{stoppingAt.cars}</span></div> : null}
    </>;
}
export const SpiScrollingStoppingPattern: React.FC<SpiScrollingStoppingPatternProps> = ({service, dynamic, alternativeServices}) => {
    const {getDimension} = useAs1428(55, dynamic || false);

    const stoppingPattern = service.stoppingAt.filter(s => s.passing);

    const bottomPaddingStations = 2;

    const [secondsToScroll, setSecondsToScroll] = useState(0);
    const scrollingContainerEl : React.MutableRefObject<HTMLDivElement|null> = useRef(null);

    const listener = () => {
        if(scrollingContainerEl && scrollingContainerEl.current){
            const height = scrollingContainerEl.current.clientHeight;
            const stopEl = scrollingContainerEl.current.querySelector('.spi-stopping-pattern-single-list-wrapper');
            const paddingEl = scrollingContainerEl.current.querySelector('.spi-stopping-pattern-single-padding');
            const textHeight = (stopEl ? stopEl.clientHeight : 112);
            const paddingHeight = (paddingEl ? paddingEl.clientHeight : 224);

            // const maxVisible = Math.floor((height+textHeight * 0.25)/textHeight);

            // const stations = stoppingPattern.length;

            if((textHeight-20) > height){
                const sts = Math.round((textHeight + paddingHeight)/112) * 1.5;
                if(sts !== secondsToScroll){
                    setSecondsToScroll(sts);
                }
            }else{
                setSecondsToScroll(0)
            }

            // stationsToScroll * 
            // console.log({height, textHeight, maxVisible, secondsToScroll});
        }
    };

    useEffect(() => {
        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        }
    }, []);

    useEffect(() => {
        listener();
    }, [scrollingContainerEl, stoppingPattern]);

    return <div className="spi-scrolling-stopping-pattern" ref={scrollingContainerEl}>
        <div className="spi-scrolling-stopping-pattern-wrapper">
            <div className="spi-scrolling-stopping-pattern-abs" style={secondsToScroll ? {
                animationDelay: '1s',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                animationDuration: `${secondsToScroll*1000}ms`,
                animationName: 'spi-stopping-pattern-scroll'
            } : {}} key={secondsToScroll}>
                <div className="spi-stopping-pattern-single-list-wrapper">
                    {alternativeServices ? <div className="spi-alt-departure-location">
                        <div className="spi-alt-departure-label">Departs: {service.stationName}</div>
                        {/* <div className="spi-alt-departure-station"></div> */}
                        <div className="spi-alt-departure-platform">{service.platformCombinedName}</div>
                    </div> : null}
                    {stoppingPattern.map((s, i) => <div key={i} className="spi-stopping-pattern-stop">
                        {renderStop(s)}
                    </div>)}
                </div>
                <div className="spi-stopping-pattern-single-padding">
                    {Array(bottomPaddingStations).fill(null).map((_, i) => <div className="spi-stopping-pattern-stop" key={i}>&nbsp;</div>)}
                </div>
                {secondsToScroll ? <div>
                    {alternativeServices ? <div className="spi-alt-departure-location">
                        <div className="spi-alt-departure-label">Departs: {service.stationName}</div>
                        {/* <div className="spi-alt-departure-station"></div> */}
                        <div className="spi-alt-departure-platform">{service.platformCombinedName}</div>
                    </div> : null}
                    {stoppingPattern.map((s, i) => <div key={i} className="spi-stopping-pattern-stop">
                        {renderStop(s)}
                    </div>)}
                    {Array(bottomPaddingStations).fill(null).map((_, i) => <div className="spi-stopping-pattern-stop" key={i}>&nbsp;</div>)}
                </div> : null}
            </div>
        </div>
    </div>
}