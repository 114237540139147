import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number, runFirst?: boolean) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
        if(savedCallback.current){
            savedCallback.current();
        }
    }

    if (runFirst) {
        tick();
    }

    if (delay !== null) {
      // console.log('mounted interval');
      let id = setInterval(tick, delay);
      return () => {
        // console.log('unmounted interval');
        clearInterval(id);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
}