import { select, delay, put, takeEvery, all } from 'redux-saga/effects';
import {  actions, actionTypes } from '../reducers';
import axios from 'axios';
import { IApiRequestTripInstanceResponse } from '../reducers/anytrip-api.types';

function* putTripInstance(){
  const {screenState, selectedTripInstance} = yield select(state => state.idi);

  if(selectedTripInstance){
    const {data} = yield axios.get(`https://anytrip.com.au/api/v3/region/${selectedTripInstance.region || 'au2'}/tripInstance/${selectedTripInstance.startDate}/${selectedTripInstance.rtTripId}/${selectedTripInstance.instanceNumber}`);
    yield put(actions.receiveTripInstanceData({tripInstanceData: data.response as IApiRequestTripInstanceResponse}));
    
    if(!screenState){
        yield put(actions.displayBegin());
    }
  }else{
      // console.warn('No selected trip instance')
  }
}

function* refreshTripInstance(){
  while (true) {
    yield putTripInstance();
    yield delay(10000);
  }
}

function* everySecond(){
  while (true) {
    yield put(actions.checkScreenState());
    yield delay(1000);
  }
}

export function* idiPolling() {
  yield all([
    refreshTripInstance(),
    everySecond(),
    takeEvery(actionTypes.SET_TRIP_INSTANCE, function* (){
      yield putTripInstance();
    })
  ]);
}
