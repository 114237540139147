import { takeEvery, select, delay, put, race, take, call } from 'redux-saga/effects';
import { actionTypes, actions } from '../reducers';
// import Speech from 'speak-tts';
import moment from 'moment';
import { IdiDisplayType, ScreenModes } from '../reducers/idi.reducer';
import { getInterchangeModes } from '../utils';

// let speech = new Speech();
// speech.init({
//     'volume': 1,
//     'lang': 'en-AU',
//     'rate': 0.85,
//     'pitch': 0.95,
//     splitSentences: false,
//     // 'voice':'Google UK English Male',
// }).then((data: any) => {
//     console.log("Speech is ready, voices are available", data);
// }).catch((e: Error) => {
//     console.error("An error occured while initializing : ", e)
// })

function ttsArrayWithConjunctive (texts: string[]){
    if(texts.length === 1){
        return texts[0];
    }else if(texts.length > 1){
        return texts.slice(0, -1).join(', ') + ' and ' + texts[texts.length-1];
    }
}

function* timedSpeech (text: string, minDuration: number) {
    // console.log(text)
    // if(speech.hasBrowserSupport()) {
    //     yield new Promise(resolve => {
    //         const begins = moment().valueOf();
    //         try{
    //             speech.speak({
    //                 text: text,
    //                 queue: false, // current speech will be interrupted,
    //                 listeners: {
    //                     onstart: () => {
    //                         console.log(text)
    //                     },
    //                     onend: () => {
    //                         const took = moment().valueOf() - begins;
    //                         const waitFor = Math.max(0, minDuration - (took));
    //                         console.log(`wait for ${waitFor}ms after saying ${text} (took ${took}ms)`)
    //                         setTimeout(() => {
    //                             resolve();
    //                         }, waitFor)
    //                     },
    //                 }
    //             })
    //         }catch(e){
    //             console.error(e)
    //         }
    //     })
    // }

    const begins = moment().valueOf();
    yield put(actions.setTtsUrl({ttsUrl: `https://sandpit.anytrip.com.au/v1/tts?strings=${encodeURIComponent(text)}`}));
    yield take(actionTypes.TTS_FINISHED);
    const took = moment().valueOf() - begins;
    const waitFor = Math.max(0, minDuration - (took));
    yield new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, waitFor)
    })
}

function* cancellableAnnounceDisplay(){
    yield put(actions.announceStart());

    const {cancel} = yield race({
        display: call(announceDisplay),
        cancel: take(actionTypes.DISPLAY_CANCEL),
    })
    // yield delay(displaySeconds*1000);

    if(cancel){
        console.log(`TTS cancelled`)
    }
    
    yield put(actions.announceEnd());
}

function* announceDisplay() {
    const { screenState, currentDisplay } = yield select(state => state.idi);

    const {surroundingStops, tripInstance, remainingStoppingPattern} = screenState;

    switch(currentDisplay.displayType){
        case IdiDisplayType.STOP_CONNECTIONS: {
            const referenceStop = surroundingStops.next;
            const parentOrReference = referenceStop.stop.parent || referenceStop.stop || '';
            const shortPlatformInterchangeModes = getInterchangeModes(parentOrReference.id);

            let changeAtForModes = '';
            if(shortPlatformInterchangeModes && shortPlatformInterchangeModes.modes){
                const modes = shortPlatformInterchangeModes.modes;
                if(modes && modes.length > 0){
                    changeAtForModes = `Change at ${referenceStop.stop.disassembled.stationName} for ${ttsArrayWithConjunctive(modes)} services.`;
                }
            }

            if(screenState.mode === ScreenModes.APPROACH){
                yield timedSpeech(`This train will stop at ${referenceStop.stop.disassembled.stationName}.  ${changeAtForModes}`, 10000);
            }else if(screenState.mode === ScreenModes.DEPARTURE){
                yield timedSpeech(`Next stop, ${referenceStop.stop.disassembled.stationName}.  ${changeAtForModes}`, 10000);
            }else if(screenState.mode === ScreenModes.MID_ROUTE || screenState.mode === ScreenModes.MID_ROUTE_NON_HIJACK){
                yield timedSpeech(`Next stop, ${referenceStop.stop.disassembled.stationName}.  ${changeAtForModes}`, 10000);
            }
            break;
        }
        case IdiDisplayType.DESTINATION: {
            if(
                screenState.mode === ScreenModes.APPROACH ||
                screenState.mode === ScreenModes.DEPARTURE
            ){
                const referenceStop = surroundingStops.at || surroundingStops.prev || surroundingStops.next;
                const headsign = (referenceStop && referenceStop.stopHeadsign) || (tripInstance.trip.headsign);
                yield delay(3000);
                yield timedSpeech(`This train goes to. ${headsign.headline} ${headsign.subtitle||''}.`, 7000);
            }
            break;
        }
        case IdiDisplayType.DOORS_OPENING: {
            const referenceStop = surroundingStops.at;
            yield timedSpeech(`This station is. ${referenceStop.stop.disassembled.stationName}.`, 10000);
            break;
        }
        case IdiDisplayType.STOPPING_PATTERN: {
            if(screenState.mode === ScreenModes.DEPARTURE){
                const pattern = remainingStoppingPattern.concat();
                const nextStop = pattern.shift();
                yield timedSpeech(`First stop. ${nextStop.stop.disassembled.stationName}.`, 3000);
                for(let i = 0; i < pattern.length; i++){
                    const subsequentStop = pattern[i];
                    const isFirst = i === 0;
                    const isLast = (i === pattern.length-1);
                    let conjunctive = '';
                    if(isFirst && isLast){
                        conjunctive = 'and then';
                    }else if(isFirst){
                        conjunctive = 'then';
                    }else if(isLast){
                        conjunctive = 'and';
                    }

                    if(conjunctive){
                        yield timedSpeech(conjunctive, 0);    
                    }
                    yield timedSpeech(subsequentStop.stop.disassembled.stationName, 1250);
                }
            }
            break;
        }
    }
    // switch()
    // yield timedSpeech(`This train goes to.`, 10000);
}

export function* idiTts() {
  yield takeEvery(actionTypes.DISPLAY_START, cancellableAnnounceDisplay);
}
