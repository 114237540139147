import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
// import geokdbush from 'geokdbush';
// import kdbush from 'kdbush';
import './spi.scss'
import './plr.scss'
// import { actions, IdiDisplayType, IdiReducerState } from '../../reducers/idi.reducer';
// import { useDispatch, useSelector } from 'react-redux';
// import { StoppingOpeningDisplay, DoorsOpeningDisplay, StopConnectionsDisplay, DestinationDisplay, PlaceholderDisplay, StopAlertDisplay, MessageAlertDisplay } from './displays';
import { useParams } from 'react-router';
import axios from 'axios';
import { IAlert, IApiRequestPidsDeparturesResponse, IPrecinctStop } from '../../reducers/anytrip-api.types';
import { useInterval } from './hooks/useInterval';
import { actions } from '../../reducers';
import useSWR from 'swr';
import SpiIndicator, {SpiScreenState} from './SpiIndicator'
import { useDispatch, useSelector } from 'react-redux';
import { SpiReducerState } from '../../reducers/spi.reducer';

const fetcher = (url: string) => axios.get(url).then(res => res.data)

export const RemoteSpiIndicator: React.FC = () => {
    const dispatch = useDispatch();
    const [versionKey, setVersionKey] = useState<string>();
    // let { screenUuid } = useParams<{screenUuid: string}>();
    // const host = window.location.host.indexOf('.uat.') > -1 ? 'anytrip-pids-server-uat.oci.jxeeno.com' : 'anytrip-pids-server-dev.oci.jxeeno.com';
    // const { data: screenData, error: screenError } = useSWR(screenUuid ? `https://${host}/screens/${screenUuid}` : null, fetcher, {refreshInterval: 1000})

    const screenError = null
    const screenData = {
        "id": "04a9996a-2dee-4a93-8154-b6a5bc5f8354",
        "name": "Sunshine",
        "location": "DoT Demo",
        "enabled": true,
        "blank": false,
        "blankText": "",
        "audio": false,
        "audioMaxFrequency": 30,
        "audioWaitBeforeRepeating": 180,
        "offlineCount": 100,
        "screenDiagonalInches": 32,
        "stopRadius": 0,
        "stopIds": [
            "au3:20021",
            "au3:22241",
            "au3:18761",
            "au3:18750",
            "au3:18763",
            "au3:18764",
            "au3:18762",
            "au3:18751",
            "au3:40946",
            "au3:22342",
            "au3:18766",
            "au3:18749",
            "au3:40947",
            "au3:18752",
            "au3:18765",
            "au3:18744",
            "au3:20275"
        ],
        "modeIds": null,
        "routeGroupIds": null,
        "views": [
            {
                "displaySeconds": 10,
                "type": "LANDSCAPE_PRECINCT_DEPARTURES_3"
            }
        ],
        "createdAt": "2022-02-24T05:46:40.468Z",
        "updatedAt": "2022-05-12T01:09:24.703Z",
        "version": 19
    };

    useEffect(() => {
        if(screenData){
            const newVersionKey = `${screenData.id}::${screenData.version}`;
            if(newVersionKey !== versionKey){
                setVersionKey(newVersionKey);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenData]);

    useEffect(() => {
        if(screenData?.screenDiagonalInches){
            dispatch(actions.setScreenDimensions({
                screenDimensions: {diagonalInches: screenData?.screenDiagonalInches}
            }))
        }

        if(screenData?.audioMaxFrequency != null){
            dispatch(actions.setMaxFrequency({
                audioMaxFrequency: screenData?.audioMaxFrequency
            }))
        }

        if(screenData?.audioWaitBeforeRepeating != null){
            dispatch(actions.setWaitBeforeRepeating({
                audioWaitBeforeRepeating: screenData?.audioWaitBeforeRepeating
            }))
        }
    }, [dispatch, screenData])

    return <RemoteSpiIndicatorInternal key={versionKey} screenData={screenData} screenError={screenError} />
}

export const RemoteSpiIndicatorInternal: React.FC<any> = ({screenData, screenError}) => {
    const [versionKey, setVersionKey] = useState<string>();
    const [viewIndex, setViewIndex] = useState<number>();
    const [viewSwitchTs, setViewSwitchTs] = useState<{start: number, displaySeconds: number}>();

    const { rotationProperties }: SpiReducerState = useSelector(
        (state: any) => state.spi
    );

    useEffect(() => {
        if(screenData){
            const newVersionKey = `${screenData.id}::${screenData.version}`;
            if(newVersionKey !== versionKey){
                setVersionKey(newVersionKey);
                if(viewIndex != null){
                    setViewIndex(0);
                    setViewSwitchTs({start: moment().unix(), displaySeconds: screenData.views[0].displaySeconds})
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenData]);

    const incrementView = () => {
        // if(viewIndex == null){return;}

        let nextScreenIndex = (viewIndex||0)+1;

        if(screenData && nextScreenIndex >= screenData.views.length){
            nextScreenIndex = 0;
        }

        if(screenData.views && screenData.views[nextScreenIndex].type === 'SERVICE_ALERT' && screenState && screenState.alerts.length === 0){
            nextScreenIndex++;

            if(nextScreenIndex >= screenData.views.length){
                nextScreenIndex = 0;
            }
        }

        // console.log('increment view', {
        //     nextScreenIndex,
        //     nextScreen: screenData.views[nextScreenIndex],
        //     alertsLength: screenState.alerts.length
        // })

        if(nextScreenIndex !== viewIndex){
            setViewIndex(nextScreenIndex);
            setViewSwitchTs({start: moment().unix(), displaySeconds: screenData.views[nextScreenIndex].displaySeconds})
        }
    }

    useInterval(() => {
        if(!screenData || !screenState){return;}

        if(viewSwitchTs == null){
            incrementView();
            return;
        }
        
        const localEndTime = viewSwitchTs.displaySeconds;
        const spiEndTime = (rotationProperties && rotationProperties.minDuration ? rotationProperties.minDuration : -Infinity);
        const ttsEndTime = -Infinity;
        const endTime = viewSwitchTs.start + Math.max(localEndTime, spiEndTime, ttsEndTime);

        // console.log({rotationProperties, endTime, localEndTime, spiEndTime})
        if(moment().unix() > endTime){
            incrementView()
        }
    }, 500, true);

    const apiDeparture = useRef<any>();
    const apiAltServices = useRef<any>();

    // state management

    const [screenState, setScreenState] = useState<SpiScreenState>();

    const getScreenState = async (regionId: string, stopId: string, altServices: boolean) => {
        const now = moment().unix();

        const state : SpiScreenState = {
            departures: [],
            services: [],
            alerts: []
        };

        let data: any;
        try{
            // const {data: d}: {data: {response: IApiRequestPidsDeparturesResponse}} = await axios.get(`http://localhost:3000/api/v3/pid/${stopId}`, {
            const {data: d}: {data: {response: IApiRequestPidsDeparturesResponse}} = await axios.get(`https://anytrip.com.au/api/v3/region/${regionId}/pid/${stopId}`, {
                params: {
                    modes: 'au3:metrotrains,au3:metrotrains,au3:trams',
                    // depArr: 'deparr',
                    limit: screenData?.offlineCount,
                    precinctStopRadius: screenData?.stopRadius ? screenData?.stopRadius : undefined,
                    offset: '0',
                    ts: now
                }
            })

            data = d;
            apiDeparture.current = data;
        }catch(e){
            if(apiDeparture.current){
                data = apiDeparture.current;
            }
        }

        state.services = data.response.departures.map((dep: any) => (dep as any).display).filter((dep: any) => dep.expectedDepartureTime >= (now - 30)); // await Promise.all(state.departures.slice(0, 3).map(departureItem => getDisplayableService(regionId, departureItem)));
        
        const additionalAlerts: IAlert[] = [];

        state.alerts = [...additionalAlerts].concat(data.response.alerts||[]).filter(alert => alert.active);

        if(altServices){
            let data: any;
            try{
                const {data: d} = await axios.get(`https://anytrip.com.au/api/v3/region/${regionId}/pid/${stopId}/alternativeServices`, {
                    params: {
                        modes: 'au2:buses,au2:tempbuses,au2:schoolbuses,au2:privatebuses,au2:nswcoaches,au2:privatecoaches,au2:lightrail,au2:ferries',
                        // depArr: 'deparr',
                        limit: '50',
                        offset: '0',
                        ts: now
                    }
                })

                data = d;
                apiAltServices.current = data;
            }catch(e){
                if(apiAltServices.current){
                    data = apiAltServices.current;
                }
            }

            apiAltServices.current = data;

            state.alternativeServices = (data.response.alternativeServices).filter((dep: any) => dep.expectedDepartureTime >= (now - 30));
        }

        // console.log(data.response)
        // state.precinctDepartures = (((data.response as any).precinctDepartures) as IDepartureItem[]).filter((dep: any) => dep.stopTimeInstance.departure.time >= (now - 30));
        // state.precinctStops = data.response.precinctStops;

        if(regionId === 'au3' || regionId === 'xx1'){
            // group by stop
            const byStopName = new Map<string, IPrecinctStop>();
            for(const stop of data.response.precinctStops){
                const key = [stop.stop.fullName, stop.stop.modes[0]].join('::');
                if(byStopName.has(key)){
                    const exStop: IPrecinctStop = byStopName.get(key) as IPrecinctStop;
                    exStop.departures.push(...stop.departures);
                    const exModeArray = exStop.stop.modes;
                    for(const mode of stop.stop.modes){
                        if(exModeArray.indexOf(mode) === -1){
                            exModeArray.push(mode);
                        }
                    }
                    exStop.departures.sort((a: any, b: any) => a.departureTime - b.departureTime)

                    if(stop.alerts && exStop.alerts){
                        for(const alert of stop.alerts){
                            if(!exStop.alerts.find(v => v.id === alert.id)){
                                exStop.alerts.push(alert);
                            }
                        }
                    }
                }else{
                    byStopName.set(key, stop);
                }
            }
            state.precinctStops = [...byStopName.values()];
        }else{
            state.precinctStops = data.response.precinctStops;
        }

        return state;
    }

    const updateScreenState = async () => {
        if(screenData?.stopIds){
            setScreenState(await getScreenState(
                'xx1',
                screenData?.stopIds.join(','),
                !!screenData?.views.find((s: any) => s.type === 'LANDSCAPE_ALT_SERVICES')
            ));
        }
    }

    useInterval(() => {
        if(screenData){
            updateScreenState();
        }
    }, 10000, true);
    
    // if(!screenData || viewIndex == null){
    //     return <div>No data</div>;
    // }

    if(!screenData || !screenState || viewIndex == null){
        return <div className="screen-ready" style={{}}>
            <div>Loading...</div>
        </div>
    }

    if(screenData.blank){
        return <div className="screen-ready" style={{}}>
            <div>{screenData.blankText || 'Service information not available'}</div>
        </div>
    }

    // set(() => {

    // }, [versionKey, viewIndex]);

    const screenVariant = screenData.views[viewIndex || 0];

    if(!screenVariant){
        return <div className="screen-ready" style={{background: "black"}}>
            <div>Screen not available</div>
        </div>
    }

    const mapping: {[k: string]: string} = {
        LANDSCAPE_WAITING_ROOM: 'split-screen-landscape-1',
        LANDSCAPE_ALT_SERVICES: 'alt-services-landscape',
        LANDSCAPE_PRECINCT_DEPARTURES: 'precinct-services-landscape',
        LANDSCAPE_PRECINCT_DEPARTURES_3: 'precinct-services-landscape-3',
        LANDSCAPE_STANDARD: 'standard-landscape',
        NEXT_SERVICE_PRECINCT_LANDSCAPE: 'split-screen-landscape-0',
        SERVICE_ALERT: 'customer-service-message'
    };

    if(mapping[screenVariant.type]){
        return <SpiIndicator mode={mapping[screenVariant.type]} screenData={screenData} screenState={screenState} />
    }

    return <div>Unknown type {screenVariant.type}</div>
}

export default RemoteSpiIndicator;
